import request from '../request'

export default {
    manageList: (data) => {
        return request({
            url: `/file/manage/list`,
            method: 'post',
            data
        })
    },
    manageDel: (id) => {
        return request({
            url: `/file/manage/delete/${id}`,
            method: 'post'
        })
    },
    manageAdd: (data) => {
        return request({
            url: `/file/manage/add`,
            method: 'post',
            data
        })
    },
    manageUpload: () => {
        return request({
            url: `/file/manage/upload`,
            method: 'post'
        })
    },
    manageUpload1: () => {
        return request({
            url: `/file/manage/upload1`,
            method: 'post',
            headers: {
                'content-type': 'application/x-www-form-urlencoded'
            }
        })
    }

}