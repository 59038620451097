import request from '../request'

export default {
  // 实验室-试剂需求日期查询
  reagentdateSearch: (data) => {
    return request({
      url: `/system/lib/reagentdate/search`,
      method: 'post',
      data
    })
  },
  // 实验室-试剂需求日期新增
  reagentdateadd: (data) => {
    return request({
      url: `/system/lib/reagentdate/add`,
      method: 'post',
      data
    })
  },
  // 实验室-试剂需求日期删除
  reagentdatedelete: (id) => {
    return request({
      url: `/system/lib/reagentdate/delete/${id}`,
      method: 'post',
    })
  },
  // 实验室-试剂需求日期详情
  reagentdatedetail: (id) => {
    return request({
      url: `/system/lib/reagentdate/detail/${id}`,
      method: 'post',
    })
  },
  // 实验室-试剂需求日期修改
  reagentdateupdate: (data) => {
    return request({
      url: `/system/lib/reagentdate/update`,
      method: 'post',
      data
    })
  },
  // 实验室-标液需求日期查询
  solutiondateSearch: (data) => {
    return request({
      url: `/system/lib/solutiondate/search`,
      method: 'post',
      data
    })
  },
  // 实验室-标液需求日期新增
  solutiondateadd: (data) => {
    return request({
      url: `/system/lib/solutiondate/add`,
      method: 'post',
      data
    })
  },
  // 实验室-标液需求日期删除
  solutiondatedelete: (id) => {
    return request({
      url: `/system/lib/solutiondate/delete/${id}`,
      method: 'post',
    })
  },
  // 实验室-标液需求日期修改
  solutiondateupdate: (data) => {
    return request({
      url: `/system/lib/solutiondate/update`,
      method: 'post',
      data
    })
  },
  // 实验室 - 试剂申购单月份需求日期下拉
  reagentDate: (month) => {
    return request({
      url: `/system/lib/reagentapplyinfo/demanddate/${month}`,
      method: 'post',
    })
  },
  // 实验室 - 查询试剂申购单
  reagentSearch: (data) => {
    return request({
      url: `/system/lib/reagentapplyinfo/search`,
      method: 'post',
      data
    })
  },
  // 实验室 - 试剂申购单下载领用二维码
  reagentDownloadQRcodeh: (data) => {
    return request({
      url: `/system/lib/reagentapplyinfo/downloadQRcode`,
      method: 'post',
      data,
      responseType: 'blob'
    })
  },
  // 实验室 - 查询标液申购单
  solutionSearch: (data) => {
    return request({
      url: `/system/lib/solutionapplyinfo/search`,
      method: 'post',
      data
    })
  },
  // 实验室 - 标液申购单下载领用二维码
  solutionDownloadQRcodeh: (data) => {
    return request({
      url: `/system/lib/solutionapplyinfo/downloadQRcode`,
      method: 'post',
      data,
      responseType: 'blob'
    })
  },
  // 实验室 - 标液申购单月份需求日期下拉
  solutionDate: (month) => {
    return request({
      url: `/system/lib/solutionapplyinfo/demanddate/${month}`,
      method: 'post',
    })
  },
  // 实验室 - 查询试剂紧急申购单
  reagentEmergencySearch: (data) => {
    return request({
      url: `/system/lib/reagentapplyinfo/emergency/search`,
      method: 'post',
      data
    })
  },
  // 实验室 - 试剂紧急申购单完成配置
  reagentEmergencyComplete: (data) => {
    return request({
      url: `/system/lib/reagentapplyinfo/completeDisposition`,
      method: 'post',
      data
    })
  },
  // 实验室 - 查询标液紧急申购单
  solutionEmergencySearch: (data) => {
    return request({
      url: `/system/lib/solutionapplyinfo/emergency/search`,
      method: 'post',
      data
    })
  },
  // 实验室 - 标液紧急申购单完成配置
  solutionEmergencyComplete: (data) => {
    return request({
      url: `/system/lib/solutionapplyinfo/completeDisposition`,
      method: 'post',
      data
    })
  },
  // 实验室 - 水样检测紧急申购单完成配置
  waterEmergencyComplete: (data) => {
    return request({
      url: `/system/lib/watertestingapplyinfo/completeDisposition`,
      method: 'post',
      data
    })
  },
  // 备品备件 - 查询备品备件申请单
  spareSearch: (data) => {
    return request({
      url: `/system/sparepartapplyinfo/search`,
      method: 'post',
      data
    })
  },
  // 实验室 - 查询水样检测申请单
  waterApplySearch: (data) => {
    return request({
      url: `/system/lib/watertestingapplyinfo/search`,
      method: 'post',
      data
    })
  },
  // 实验室 - 下载紧急申请领用二维码
  reagentQRcode: (batchNumber) => {
    return request({
      url: `/system/lib/reagentapplyinfo/emergency/downloadQRcode/?batchNumber=${batchNumber}`,
      method: 'post',
    })
  },
  // 实验室 - 下载紧急申请领用二维码
  solutionQRcode: (batchNumber) => {
    return request({
      url: `/system/lib/solutionapplyinfo/emergency/downloadQRcode/?batchNumber=${batchNumber}`,
      method: 'post',
    })
  },
  // 查询试剂审报清单列表
  reagentSearchApply: (data) => {
    return request({
      url: `/system/lib/reagentapplyinfo/searchApply`,
      method: 'post',
      data,
    })
  },
  // 查询标液申请- 标液详情列表
  reagentSearchDetail: (batchNumber) => {
    return request({
      url: `/system/lib/reagentapplyinfo/info/${batchNumber}`,
      method: 'post',
    })
  },
  // 查询试剂申请-试剂详情列表
  solutionSearchApply: (data) => {
    return request({
      url: `/system/lib/solutionapplyinfo/searchApply`,
      method: 'post',
      data,
    })
  },
  // 查询标液申请- 标液详情列表
  solutionSearchDetail: (batchNumber) => {
    return request({
      url: `/system/lib/solutionapplyinfo/info/${batchNumber}`,
      method: 'post',
    })
  },
  // 查询水样检测审报清单列表
  waterSearchApply: (data) => {
    return request({
      url: `/system/lib/watertestingapplyinfo/searchApply`,
      method: 'post',
      data,
    })
  },
  // 试剂-根据领用批次号查询审批流程
  reagentProcessInfo: (batchNumber) => {
    return request({
      url: `/system/lib/reagentapplyinfo/processInfo/${batchNumber}`,
      method: 'post',
    })
  },
  // 标液-根据领用批次号查询审批流程
  solutionProcessInfo: (batchNumber) => {
    return request({
      url: `/system/lib/solutionapplyinfo/processInfo/${batchNumber}`,
      method: 'post',
    })
  },
  // 水样检测-根据领用批次号查询审批流程
  waterProcessInfo: (batchNumber) => {
    return request({
      url: `/system/lib/watertestingapplyinfo/processInfo/${batchNumber}`,
      method: 'post',
    })
  },
}