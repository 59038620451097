import request from '../request'

export default {
 // 现场督查配置 - 查询
 deptopSupervisionList: (data) => {
    return request({
      url: `/system/dept/opSiteSupervisionVersion/search`,
      method: 'post',
      data
    })
  },
  // 现场督查配置 - 新增
  deptopSupervisionAdd: (data) => {
    return request({
      url: `/system/dept/opSiteSupervisionVersion/add`,
      method: 'post',
      data
    })
  },
  // 现场督查配置 - 删除
  deptopSupervisionDel: (id) => {
    return request({
      url: `/system/dept/opSiteSupervisionVersion/delete/${id}`,
      method: 'post',
    })
  },
  // 现场督查配置 - 修改
  deptopSupervisionUpdate: (data) => {
    return request({
      url: `/system/dept/opSiteSupervisionVersion/update`,
      method: 'post',
      data
    })
  },
  // 现场督查配置 - 详情
  deptopSupervisionInfo: (id) => {
    return request({
      url: `/system/dept/opSiteSupervisionVersion/info/${id}`,
      method: 'post',
    })
  },
  // 现场督查配置 - 停用
  deptopSupervisionDis: (id) => {
    return request({
      url: `/system/dept/opSiteSupervisionVersion/disable/${id}`,
      method: 'post',
    })
  },
  // 现场督查配置 - 启用
  deptopSupervisionEn: (id) => {
    return request({
      url: `/system/dept/opSiteSupervisionVersion/enable/${id}`,
      method: 'post',
    })
  },
  // 检查项配置 - 事项分类下拉
  deptopSupervisionItemType: (type) => {
    return request({
      url: `/system/dept/opSiteSupervisionItem/typelist?type=${type}`,
      method: 'post',
    })
  },
  // 检查项配置 - 查询
  deptopSupervisionItemList: (data) => {
    return request({
      url: `/system/dept/opSiteSupervisionItem/search`,
      method: 'post',
      data,
    })
  },
  // 检查项配置 - 详情
  deptopSupervisionItemInfo: (id) => {
    return request({
      url: `/system/dept/opSiteSupervisionItem/info/${id}`,
      method: 'post',
    })
  },
  // 检查项配置 - 新增
  deptopSupervisionItemAdd: (data) => {
    return request({
      url: `/system/dept/opSiteSupervisionItem/add`,
      method: 'post',
      data,
    })
  },
  // 检查项配置 - 修改
  deptopSupervisionItemUpdate: (id,data) => {
    return request({
      url: `/system/dept/opSiteSupervisionItem/update/${id}`,
      method: 'post',
      data,
    })
  },
  // 检查项配置 - 删除
  deptopSupervisionItemDel: (id) => {
    return request({
      url: `/system/dept/opSiteSupervisionItem/delete/${id}`,
      method: 'post',
    })
  },
  // 现场督查检查事项选项 - 新增
  deptopSupervisionItemInfoAdd: (data) => {
    return request({
      url: `/system/dept/opSiteSupervisionItemInfo/addbatch`,
      method: 'post',
      data,
    })
  },
  // 现场督查检查事项选项 - 删除
  deptopSupervisionItemInfoDel: (data) => {
    return request({
      url: `/system/dept/opSiteSupervisionItemInfo/deletebatch`,
      method: 'post',
      data
    })
  },
  // 现场督查检查事项选项 - 修改
  deptopSupervisionItemInfoUpdate: (data) => {
    return request({
      url: `/system/dept/opSiteSupervisionItemInfo/update`,
      method: 'post',
      data,
    })
  },
  // 现场督查记录 - 查询
  deptopSupervisionRecordList: (data) => {
    return request({
      url: `/system/dept/opSiteSupervisionRecord/search`,
      method: 'post',
      data,
    })
  },
  // 现场督查记录 - 详情
  deptopSupervisionRecordDetail: (id) => {
    return request({
      url: `/system/dept/opSiteSupervisionRecord/searchdetail/${id}`,
      method: 'post',
    })
  },
  // 现场督查记录 - 查看整改项
  deptopSupervisionRecordCor: (recordId) => {
    return request({
      url: `/system/dept/opSiteSupervisionRecord/queryCorrective/${recordId}`,
      method: 'post',
    })
  },
  // 现场督查统计
  deptopSupervisionRecordCount: (data) => {
    return request({
      url: `/system/dept/opSiteSupervisionRecord/count`,
      method: 'post',
      data,
    })
  },
  // 按检查项统计
  deptopSupervisionCount: (data) => {
    return request({
      url: `/system/dept/opSiteSupervisionRecord/supervisioncount`,
      method: 'post',
      data,
    })
  },
}