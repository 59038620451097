<template>
  <div id="app">
    <router-view class="view"/>
  </div>
</template>

<script>

export default {
  name: "App"
};
</script>

<style lang="less">
@primary-color: rgba(83,175,59,1);
html,
body,
.view {
  height: 100%;
}
#app {
  font-family: "Avenir", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
}

/* css utils */
.clearfix {
  zoom: 1;
}
.clearfix:after {
  content: ".";
  display: block;
  height: 0;
  clear: both;
  visibility: hidden;
}
.ivu-carousel-arrow {
  line-height: 1;
}
.demo-table-info-cell-age {
      color: #ff6600;
    }
</style>
