/*
 * @Author: winbin winbin@live.com
 * @Date: 2022-08-22 15:20:36
 * @LastEditors: zhuhongyi 1269061113@qq.com
 * @LastEditTime: 2023-03-31 14:55:47
 * @FilePath: \jx2qi\src\libs\api\request.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import Axios from 'axios'
import { Message, Spin } from 'view-design'

const controller = new AbortController();

const service = Axios.create({
    async: true,
    crossDomain: true,
    baseURL: process.env.VUE_APP_API_HOST,
    timeout: 20000,
    signal: controller.signal,
    headers: {
        "Content-Type": "application/json; charset=utf-8",
    },
});

Axios.defaults.withCredentials = true;

var requestList = [];
//过滤请求事件
const stopRepeatRequest = (reqList, url, errorMessage) => {
    if (reqList.length) {
        const arr = reqList.filter((item) => item === url);
        if (arr.length) {
            Message.error(errorMessage);
            return true;
        }
    }
    reqList.push(url);
    setTimeout(() => {
        requestList = reqList.filter((item) => item !== url);
    }, 2000);
};
//接口请求完成后清除
const allowRequest = (reqList, url) => {
    if (reqList.length) {
        requestList = reqList.filter((item) => item !== url);
    }
};

service.interceptors.request.use(
    async (config) => {
        // const flag = await stopRepeatRequest(requestList, config.url, `${config.url} 2秒内只能请求一次`)
        // if (flag) return
        let token = sessionStorage.getItem("token");
        config.headers['satoken'] = token
        // Spin.show()
        return config
    }, error => {
        return Promise.reject(error)
    })

service.interceptors.response.use(
    (response) => {
        allowRequest(
            requestList,
            response.config.url.replace(response.config.baseURL, "")
        );
        if (response.config.responseType === "blob") {
            if (response.data.type == "application/json") {
                let reader = new FileReader();
                reader.readAsArrayBuffer(response.data);
                reader.onload = function () {
                    let enc = new TextDecoder("utf-8");
                    let r = JSON.parse(enc.decode(new Uint8Array(reader.result)));
                    Message.error(r.msg);
                    return Promise.resolve(false);
                };
            } else {
                return Promise.resolve(response.data);
            }
        }
        // Spin.hide()
        if (response.data.code === 200) {
            return Promise.resolve(response.data);
        } else if (response.data.code === 401) {
            Message.error(response.data.msg);
            window.$vm.$router.push("/login");
        } else {
            Message.error(response.data.msg);
            return Promise.resolve(false);
        }
    }, error => {
        allowRequest(requestList, error.config.url.replace(error.config.baseURL, ''))
        return Promise.reject(error)
    })

export default service
