import request from "../request";
const AMAP_KEY = "035e78616305b6ac9a1e1f13fd9b1fda";

export default {
  // 部门接口相关 - 部门新增
  addDept: (data) => {
    return request({
      url: `/system/dept/add`,
      method: "post",
      data,
    });
  },
  // 部门接口相关 - 部门删除
  delDept: (deptId) => {
    return request({
      url: `/system/dept/delete/${deptId}`,
      method: "post",
    });
  },
  // 部门接口相关 - 查询部门树
  deptList: () => {
    return request({
      url: `/system/dept/queryTree`,
      method: "post",
    });
  },
  // 查询所有的部门树
  deptAllList: () => {
    return request({
      url: `/system/dept/queryAllTree`,
      method: "post",
    });
  },
  // 部门接口相关 - 部门修改
  editDept: (deptId, data) => {
    return request({
      url: `/system/dept/update/${deptId}`,
      method: "post",
      data,
    });
  },
  // 菜单接口相关 - 菜单新增
  addMenu: (data) => {
    return request({
      url: `/system/menu/add`,
      method: "post",
      data,
    });
  },
  // 菜单接口相关 - 菜单删除
  delMenu: (menuId) => {
    return request({
      url: `/system/menu/delete/${menuId}`,
      method: "post",
    });
  },
  // 菜单接口相关 - 查询所有的菜单树
  menuList: () => {
    return request({
      url: `/system/menu/queryAllTree`,
      method: "post",
    });
  },
  // 菜单接口相关 - 根据使用端查询当前用户拥有菜单树
  getPermissions: (menuUsage) => {
    return request({
      url: `/system/menu/queryPermissions/${menuUsage}/{userId}`,
      method: "post",
    });
  },
  // 菜单接口相关 - 根据使用端查询当前用户拥有菜单树
  getMenuTree: (menuUsage) => {
    return request({
      url: `/system/menu/queryTreeByUsage/${menuUsage}`,
      method: "post",
    });
  },
  // 菜单接口相关 - 菜单修改
  editMenu: (menuId, data) => {
    return request({
      url: `/system/menu/update/${menuId}`,
      method: "post",
      data,
    });
  },
  // 角色接口相关 - 角色新增
  addRole: (data) => {
    return request({
      url: `/system/role/add`,
      method: "post",
      data,
    });
  },
  // 角色接口相关 - 角色删除
  delRole: (roleId) => {
    return request({
      url: `/system/role/delete/${roleId}`,
      method: "post",
    });
  },
  // 角色接口相关 - 列表查询
  roleList: (data) => {
    return request({
      url: `/system/role/list`,
      method: "post",
      data,
    });
  },
  // 角色接口相关 - 角色修改
  editRole: (roleId, data) => {
    return request({
      url: `/system/role/update/${roleId}`,
      method: "post",
      data,
    });
  },
  // 用户接口相关 - 用户新增
  addUser: (data) => {
    return request({
      url: `/system/user/add`,
      data,
      method: "post",
    });
  },
  // 用户接口相关 - 删除
  delUser: (userId) => {
    return request({
      url: `/system/user/delete/${userId}`,
      method: "post",
    });
  },
  // 用户接口相关 - 列表查询
  userList: (data) => {
    return request({
      url: `/system/user/list`,
      method: "post",
      data,
    });
  },
  // 用户接口相关 - 修改
  editUser: (userId, data) => {
    return request({
      url: `/system/user/update/${userId}`,
      data,
      method: "post",
    });
  },
  // 用户接口相关 - 分配数据权限
  userGrant: (data) => {
    return request({
      url: `/system/user/grant`,
      data,
      method: "post",
    });
  },
  // 系统日志接口相关 - 列表查询
  sysLogList: (data) => {
    return request({
      url: `/system/sysLog/list`,
      method: "post",
      data,
    });
  },
  operationPanel: () => {
    return request({
      url: `/system/sysLog/queryOperationPanel`,
      method: "post",
    });
  },
  // 站点接口相关 - 查询指定部门的树形结构的站点信息
  stationTreeByDept: (deptId) => {
    return request({
      url: `/system/station/queryTreeByDept/${deptId}`,
      method: "post",
    });
  },
  // 站点接口相关 - 查询当前用户的树形结构的站点信息
  stationTree: () => {
    return request({
      url: `/system/station/queryTree`,
      method: "post",
    });
  },
  stationUserTree: (id) => {
    return request({
      url: `/system/station/queryTree/${id}`,
      method: "post",
    });
  },
  stationQueryTreeByType: (type) => {
    return request({
      url: `/system/station/queryTreeByType/${type}`,
      method: "post",
    });
  },
  exportStation: (data) => {
    return request({
      url: `/system/station/export`,
      data,
      method: "post",
      responseType: "blob",
    });
  },
  // stationEnableAndDisable: (stationId) => {
  //     return request({
  //         url: `/system/station/enableAndDisable/${stationId}`,
  //         method: 'post'
  //     })
  // },
  stationEnable: (stationId) => {
    return request({
      url: `/system/station/enable/${stationId}`,
      method: "post",
    });
  },
  stationPaused: (stationId) => {
    return request({
      url: `/system/station/paused/${stationId}`,
      method: "post",
    });
  },
  stationAborted: (stationId) => {
    return request({
      url: `/system/station/aborted/${stationId}`,
      method: "post",
    });
  },

  // 企业与运维厂接口相关 - 列表查询
  enterpriseList: (data) => {
    return request({
      url: `/system/enterprise/list`,
      data,
      method: "post",
    });
  },
  // 企业与运维厂接口相关 - 新增
  enterpriseAdd: (data) => {
    return request({
      url: `/system/enterprise/add`,
      data,
      method: "post",
    });
  },
  // 企业与运维厂接口相关 - 删除
  enterpriseDelete: (enterpriseId) => {
    return request({
      url: `/system/enterprise/delete/${enterpriseId}`,
      method: "post",
    });
  },
  // 企业与运维厂接口相关 - 修改
  enterpriseUpdate: (enterpriseId, data) => {
    return request({
      url: `/system/enterprise/update/${enterpriseId}`,
      data,
      method: "post",
    });
  },
  // 企业与运维厂接口相关 - 详情
  enterpriseDetail: (id) => {
    return request({
      url: `/system/enterprise/info/${id}`,
      method: "post",
    });
  },
  // 站点接口相关 - 列表查询
  stationList: (data) => {
    return request({
      url: `/system/station/list`,
      data,
      method: "post",
    });
  },
  // 站点接口相关 - 新增
  stationAdd: (data) => {
    return request({
      url: `/system/station/add`,
      data,
      method: "post",
    });
  },
  // 站点接口相关 - 删除
  stationDelete: (stationId) => {
    return request({
      url: `/system/station/delete/${stationId}`,
      method: "post",
    });
  },
  // 站点接口相关 - 修改
  stationUpdate: (stationId, data) => {
    return request({
      url: `/system/station/update/${stationId}`,
      data,
      method: "post",
    });
  },
  // 企业与运维厂接口相关 - 详情
  stationDetail: (id) => {
    return request({
      url: `/system/station/info/${id}`,
      method: "post",
    });
  },
  // 根据父级ID查询运维厂
  queryListByParentId: (parentId) => {
    return request({
      url: `/system/enterprise/queryListByParentId/${parentId}`,
      method: "post",
    });
  },
  // 查询运维企业
  queryEnterprise: () => {
    return request({
      url: `/system/enterprise/queryEnterprise`,
      method: "post",
    });
  },
  // 查询指定部门的树形结构的站点信息
  queryTreeByDept: (deptId) => {
    return request({
      url: `/system/station/queryTreeByDept/${deptId}`,
      method: "post",
    });
  },
  // 运维整改 - 列表查询
  operationList: (data) => {
    return request({
      url: `/system/opMaintenanceRatingCorrective/list`,
      data,
      method: "post",
    });
  },
  // 运维整改 - 发起运维整改
  operationLAdd: (data) => {
    return request({
      url: `/system/opMaintenanceRatingCorrective/add`,
      data,
      method: "post",
    });
  },
  // 运维整改 - 详情
  operationDetail: (id) => {
    return request({
      url: `/system/opMaintenanceRatingCorrective/info/${id}`,
      method: "post",
    });
  },
  // 运维整改 - 修改
  operationUpdate: (id, data) => {
    return request({
      url: `/system/opMaintenanceRatingCorrective/update/${id}`,
      data,
      method: "post",
    });
  },
  // 运维整改 - 删除
  operationDelete: (id) => {
    return request({
      url: `/system/opMaintenanceRatingCorrective/delete/${id}`,
      method: "post",
    });
  },
  // 根据事件ID和事件类型查询相关流程
  queryByEvent: (eventId, eventType) => {
    return request({
      url: `/system/eventNode/queryByEvent/${eventId}/${eventType}`,
      method: "post",
    });
  },
  // 试剂台账 - 查询
  reagentList: (data) => {
    return request({
      url: `/system/reagentLedger/list`,
      data,
      method: "post",
    });
  },
  // 试剂台账 - 新增
  reagentAdd: (data) => {
    return request({
      url: `/system/reagentLedger/add`,
      data,
      method: "post",
    });
  },
  // 试剂台账 - 详情
  reagentDetail: (id) => {
    return request({
      url: `/system/reagentLedger/info/${id}`,
      method: "post",
    });
  },
  // 试剂台账 - 删除
  reagentDelete: (id) => {
    return request({
      url: `/system/reagentLedger/delete/${id}`,
      method: "post",
    });
  },
  // 试剂台账 - 修改
  reagentUpdate: (id, data) => {
    return request({
      url: `/system/reagentLedger/update/${id}`,
      data,
      method: "post",
    });
  },
  // 试剂类型下拉
  reagentType: () => {
    return request({
      url: `/system/reagentLedger/types`,
      method: "post",
    });
  },
  // 所有试剂
  reagentAll: () => {
    return request({
      url: `/system/reagentLedger/allList`,
      method: "post",
    });
  },
  // 标液台账 - 查询
  reagentinfoList: (data) => {
    return request({
      url: `/system/reagentinfo/list`,
      data,
      method: "post",
    });
  },
  // 标液台账 - 新增
  reagentinfoAdd: (data) => {
    return request({
      url: `/system/reagentinfo/add`,
      data,
      method: "post",
    });
  },
  // 标液台账 - 详情
  reagentinfoDetail: (id) => {
    return request({
      url: `/system/reagentinfo/info/${id}`,
      method: "post",
    });
  },
  // 标液台账 - 删除
  reagentinfoDelete: (id) => {
    return request({
      url: `/system/reagentinfo/delete/${id}`,
      method: "post",
    });
  },
  // 标液台账 - 修改
  reagentinfoUpdate: (data) => {
    return request({
      url: `/system/reagentinfo/update`,
      data,
      method: "post",
    });
  },
  // 标液类型下拉
  reagentinfoType: () => {
    return request({
      url: `/system/reagentinfo/types`,
      method: "post",
    });
  },
  // 备品备件台账 - 查询
  sparepartinfoList: (data) => {
    return request({
      url: `/system/sparepartinfo/list`,
      data,
      method: "post",
    });
  },
  // 备品备件台账 - 新增
  sparepartinfoAdd: (data) => {
    return request({
      url: `/system/sparepartinfo/add`,
      data,
      method: "post",
    });
  },
  // 备品备件台账 - 详情
  sparepartinfoDetail: (id) => {
    return request({
      url: `/system/sparepartinfo/info/${id}`,
      method: "post",
    });
  },
  // 备品备件台账 - 删除
  sparepartinfoDelete: (id) => {
    return request({
      url: `/system/sparepartinfo/delete/${id}`,
      method: "post",
    });
  },
  // 备品备件台账 - 修改
  sparepartinfoUpdate: (data) => {
    return request({
      url: `/system/sparepartinfo/update`,
      data,
      method: "post",
    });
  },
  // 备品备件类型下拉
  sparepartinfoType: () => {
    return request({
      url: `/system/sparepartinfo/types`,
      method: "post",
    });
  },
  // 运维事项分组 - 列表
  opMaintenanceGroupList: (data) => {
    return request({
      url: `/system/opMaintenanceGroup/list`,
      data,
      method: "post",
    });
  },
  // 运维事项分组 - 新增
  opMaintenanceGroupAdd: (data) => {
    return request({
      url: `/system/opMaintenanceGroup/add`,
      data,
      method: "post",
    });
  },
  // 运维事项分组 - 删除
  opMaintenanceGroupDel: (id) => {
    return request({
      url: `/system/opMaintenanceGroup/delete/${id}`,
      method: "post",
    });
  },
  // 运维事项分组 - 修改
  opMaintenanceGroupUp: (id, data) => {
    return request({
      url: `/system/opMaintenanceGroup/update/${id}`,
      data,
      method: "post",
    });
  },
  // 运维事项 - 列表
  OpMaintenanceItemList: (data) => {
    return request({
      url: `/system/OpMaintenanceItem/list`,
      data,
      method: "post",
    });
  },
  // 运维事项 - 新增
  OpMaintenanceItemAdd: (data) => {
    return request({
      url: `/system/OpMaintenanceItem/add`,
      data,
      method: "post",
    });
  },
  // 运维事项 - 删除
  OpMaintenanceItemDel: (id) => {
    return request({
      url: `/system/OpMaintenanceItem/delete/${id}`,
      method: "post",
    });
  },
  // 运维事项 - 修改
  OpMaintenanceItemUp: (id, data) => {
    return request({
      url: `/system/OpMaintenanceItem/update/${id}`,
      data,
      method: "post",
    });
  },
  // 运维事项 - 详情
  OpMaintenanceItemInfo: (id) => {
    return request({
      url: `/system/OpMaintenanceItem/info/${id}`,
      method: "post",
    });
  },

  // 运维计划 - 列表
  opMaintenancePlanList: (data) => {
    return request({
      url: `/system/opMaintenancePlan/list`,
      data,
      method: "post",
    });
  },
  // 运维计划 - 新增
  opMaintenancePlanAdd: (data) => {
    return request({
      url: `/system/opMaintenancePlan/add`,
      data,
      method: "post",
    });
  },
  // 运维计划 - 删除
  opMaintenancePlanDel: (id) => {
    return request({
      url: `/system/opMaintenancePlan/delete/${id}`,
      method: "post",
    });
  },
  // 运维计划 - 修改
  opMaintenancePlanUp: (id, data) => {
    return request({
      url: `/system/opMaintenancePlan/update/${id}`,
      data,
      method: "post",
    });
  },
  // 运维计划 - 启用
  opMaintenancePlanStart: (id) => {
    return request({
      url: `/system/opMaintenancePlan/start/${id}`,
      method: "post",
    });
  },
  // 运维计划 - 停用
  opMaintenancePlanStop: (id) => {
    return request({
      url: `/system/opMaintenancePlan/stop/${id}`,
      method: "post",
    });
  },
  // 运维评分记录 - 列表
  opMaintenanceRecordList: (data) => {
    return request({
      url: `/system/opMaintenanceRecord/list`,
      data,
      method: "post",
    });
  },
  // 运维评分记录 - 导出
  opMaintenanceRecordExport: (data) => {
    return request({
      url: `/system/opMaintenanceRecord/export`,
      data,
      method: "post",
      responseType: "blob",
    });
  },
  // 运维评分记录 - 删除
  opMaintenanceRecordDel: (id) => {
    return request({
      url: `/system/opMaintenanceRecord/delete/${id}`,
      method: "post",
    });
  },
  // 运维评分记录 - 详情
  opMaintenanceRecordInfo: (id) => {
    return request({
      url: `/system/opMaintenanceRecord/info/${id}`,
      method: "post",
    });
  },
  // 运维评分记录 - 运维评分详情
  opMaintenanceRatingInfo: (id) => {
    return request({
      url: `/system/opMaintenanceRatingInfo/info/${id}`,
      method: "post",
    });
  },
  // 运维评分记录 - 询运维记录填报后的运维事项
  opMaintenanceRecordQueryRatingInfo: (id) => {
    return request({
      url: `/system/opMaintenanceRecord/queryRatingInfo/${id}`,
      method: "post",
    });
  },
  //智慧站点
  wisdomStation: (data) => {
    return request({
      url: `/system/station/wisdomStation`,
      data,
      method: "post",
    });
  },
  //督查评分
  opMaintenanceRatingInfoRating: (data) => {
    return request({
      url: `/system/opMaintenanceRatingInfo/rating`,
      data,
      method: "post",
    });
  },
  // 发起整改
  opMaintenanceRatingCorrectiveAdd: (data) => {
    return request({
      url: `/system/opMaintenanceRatingCorrective/add`,
      data,
      method: "post",
    });
  },
  // 数采仪列表
  acquisitionInstrumentList: (data) => {
    return request({
      url: `/system/acquisitionInstrument/list`,
      data,
      method: "post",
    });
  },
  // 数采仪添加
  acquisitionInstrumentAdd: (data) => {
    return request({
      url: `/system/acquisitionInstrument/add`,
      data,
      method: "post",
    });
  },
  // 数采仪编辑
  acquisitionInstrumentUpdate: (id, data) => {
    return request({
      url: `/system/acquisitionInstrument/update/${id}`,
      data,
      method: "post",
    });
  },
  // 数采仪删除
  acquisitionInstrumentDel: (id) => {
    return request({
      url: `/system/acquisitionInstrument/delete/${id}`,
      method: "post",
    });
  },
  // 数采仪详情
  acquisitionInstrumentInfo: (id) => {
    return request({
      url: `/system/acquisitionInstrument/info/${id}`,
      method: "post",
    });
  },
  // 数采仪查询设备
  equipmentList: (id) => {
    return request({
      url: `/system/equipment/queryByAcquisitionInstrumentId/${id}`,
      method: "post",
    });
  },
  // 数采仪设备添加
  equipmentAdd: (data) => {
    return request({
      url: `/system/equipment/add`,
      data,
      method: "post",
    });
  },
  // 数采仪设备编辑
  equipmentEdit: (id, data) => {
    return request({
      url: `/system/equipment/update/${id}`,
      data,
      method: "post",
    });
  },
  // 数采仪设备删除
  equipmentDel: (id) => {
    return request({
      url: `/system/equipment/delete/${id}`,
      method: "post",
    });
  },
  // 数采仪设备详情
  equipmentInfo: (id) => {
    return request({
      url: `/system/equipment/info/${id}`,
      method: "post",
    });
  },
  // 站点查询
  stationSelect: (data) => {
    return request({
      url: `/system/station/select`,
      data,
      method: "post",
    });
  },
  // 查询与我相关行政区域站点列表
  areaStation: () => {
    return request({
      url: `/system/station/areaStation`,
      method: "post",
    });
  },
  // 通过站点查询用户
  userSelectByStation: (id) => {
    return request({
      url: `/system/user/selectByStation/${id}`,
      method: "post",
    });
  },
  // 通过部门查询用户
  userSelectByDeptId: (id) => {
    return request({
      url: `/system/user/selectByDeptId/${id}`,
      method: "post",
    });
  },
  // 节点驳回
  eventNodeRejected: (id, data) => {
    return request({
      url: `/system/eventNode/rejected/${id}`,
      data,
      method: "post",
    });
  },
  // 节点通过
  eventNodeFinish: (id, data) => {
    return request({
      url: `/system/eventNode/finish/${id}`,
      data,
      method: "post",
    });
  },
  // 节点转交
  eventNodeTransfer: (id, data) => {
    return request({
      url: `/system/eventNode/transfer/${id}`,
      data,
      method: "post",
    });
  },
  // 根据站点ID查询修约数据
  queryModifyData: (data) => {
    return request({
      url: `/system/onlineData/queryModifyData`,
      data,
      method: "post",
    });
  },
  // 根据站点ID查询修约历史记录
  queryModifyLog: (data) => {
    return request({
      url: `/system/onlineData/queryModifyLog`,
      data,
      method: "post",
    });
  },
  // 人工修约
  modifyData: (data) => {
    return request({
      url: `/system/onlineData/modifyData`,
      data,
      method: "post",
    });
  },
  // 根据站点ID查询在线监测数据
  queryOnlineData: (data) => {
    return request({
      url: `/system/onlineData/queryOnlineData`,
      data,
      method: "post",
    });
  },
  // 根据站点id查询设备列表
  equipmentQueryByStationId: (stationId) => {
    return request({
      url: `/system/equipment/queryByStationId/${stationId}`,
      method: "post",
    });
  },
  // 运维站点试剂列表
  stationReagentlist: (params) => {
    return request({
      url: `/system/mini/homepage/reagentlist`,
      params,
      method: "post",
    });
  },
  // 运维站点备品备件列表
  stationSparepartlist: (params) => {
    return request({
      url: `/system/mini/homepage/sparepartlist`,
      params,
      method: "post",
    });
  },
  // 站点档案
  stationArchivesList: (data) => {
    return request({
      url: `/system/stationArchives/list`,
      data,
      method: "post",
    });
  },
  stationArchivesAdd: (data) => {
    return request({
      url: `/system/stationArchives/add`,
      data,
      method: "post",
    });
  },
  stationArchivesDel: (id) => {
    return request({
      url: `/system/stationArchives/delete/${id}`,
      method: "post",
    });
  },
  stationReportList: (data) => {
    return request({
      url: `/system/stationReport/list`,
      data,
      method: "post",
    });
  },
  queryDictData: (dictType) => {
    return request({
      url: `/system/dictData/queryDictData/${dictType}`,
      method: "post",
    });
  },
  queryDictDataChildren: (dictDataId, dictType) => {
    return request({
      url: `/system/dictData/queryChildren/${dictDataId}/${dictType}`,
      method: "post",
    });
  },
  // 运维事项分组 - 复制分组
  opMaintenanceGroupCopy: (data) => {
    return request({
      url: `/system/opMaintenanceGroup/copy`,
      data,
      method: "post",
    });
  },
  queryFactoryByArea: (data) => {
    return request({
      url: `/system/enterprise/queryFactoryByArea`,
      data,
      method: "post",
    });
  },
  emissionStandardSelect: () => {
    return request({
      url: `/system/emissionStandard/select`,
      method: "post",
    });
  },
  emissionStandardList: (data) => {
    return request({
      url: `/system/emissionStandard/list`,
      data,
      method: "post",
    });
  },
  emissionStandardAdd: (id, data) => {
    return request({
      url: `/system/emissionStandard/add`,
      data,
      method: "post",
    });
  },
  emissionStandardUpdate: (id, data) => {
    return request({
      url: `/system/emissionStandard/update/${id}`,
      data,
      method: "post",
    });
  },
  emissionStandardDel: (id) => {
    return request({
      url: `/system/emissionStandard/delete/${id}`,
      method: "post",
    });
  },
  // 字典类型下拉
  dictRef: () => {
    return request({
      url: `/system/dictType/select`,
      method: "post",
    });
  },
  // 根据字典数据ID查询字典数据下级列表
  dictDataQuery: (dictDataId, dictType) => {
    return request({
      url: `/system/dictData/queryChildren/${dictDataId}/${dictType}`,
      method: "post",
    });
  },
  // 上级字典下拉
  dictDataSelect: (data) => {
    return request({
      url: `/system/dictData/select`,
      data,
      method: "post",
    });
  },
  // 设备字典管理-列表
  dictDataList: (data) => {
    return request({
      url: `/system/dictData/list`,
      data,
      method: "post",
    });
  },
  // 设备字典管理-新增
  dictDataAdd: (data) => {
    return request({
      url: `/system/dictData/add`,
      data,
      method: "post",
    });
  },
  // 设备字典管理-修改
  dictDataUpdate: (id, data) => {
    return request({
      url: `/system/dictData/update/${id}`,
      data,
      method: "post",
    });
  },
  // 设备字典管理-详情
  dictDataInfo: (id) => {
    return request({
      url: `/system/dictData/info/${id}`,
      method: "post",
    });
  },
  // 设备字典管理-删除
  dictDataDelete: (id) => {
    return request({
      url: `/system/dictData/delete/${id}`,
      method: "post",
    });
  },
  // 版本管理
  versionRecordAdd: (data) => {
    return request({
      url: `/system/versionRecord/add`,
      method: "post",
      data,
    });
  },
  versionRecordEdit: (id, data) => {
    return request({
      url: `/system/versionRecord/update/${id}`,
      method: "post",
      data,
    });
  },
  versionRecordDel: (id) => {
    return request({
      url: `/system/versionRecord/delete/${id}`,
      method: "post",
    });
  },
  versionRecordNow: (type) => {
    return request({
      url: `/system/versionRecord/getVerSion/${type}`,
      method: "post",
    });
  },
  versionRecordList: (data) => {
    return request({
      url: `/system/versionRecord/list/`,
      method: "post",
      data,
    });
  },
  updatePushConfig: (data) => {
    return request({
      url: `/system/station/updatePushConfig`,
      method: "post",
      data,
    });
  },
  queryPushConfig: (id) => {
    return request({
      url: `/system/station/queryPushConfig/${id}`,
      method: "post"
    })
  },
  //  报警管理(企业)-本月概况
  overviewMonth: () => {
    return request({
      url: `/system/alarm/overviewMonth`,
      method: "post",
    });
  },
  stationStatisticsDeptStatistics: (data) => {
    return request({
      url: `/system/stationStatistics/deptStatistics`,
      method: "post",
      data
    });
  },
  stationStatisticsStatistics: (data) => {
    return request({
      url: `/system/stationStatistics/statistics`,
      method: "post",
      data
    });
  },
  stationStatisticsUserStatistics: (data) => {
    return request({
      url: `/system/stationStatistics/userStatistics`,
      method: "post",
      data
    });
  },
};
