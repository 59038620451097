<!--
 * @Author: winbin
 * @Date: 2022-08-25 09:06:18
 * @LastEditors: winbin
 * @LastEditTime: 2023-03-03 11:10:38
 * @FilePath: \jx2qi\src\components\routerView.vue
 * @Description: Desc
-->
<template>
  <KeepAlive :include="routers">
    <router-view></router-view>
  </KeepAlive>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
export default {
  computed:{
    ...mapGetters(["menuList", "tabList"]),
    routers(){
      let rt = []
      this.tabList.map(val=>{
        rt.push(val.name)
      })
      return rt
    }
  }
}
</script>

<style>

</style>