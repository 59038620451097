import Main from "@/components/main";
import RouterView from "@/components/routerView";
export const loginRouter = {
  path: "/login",
  name: "login",
  meta: {
    title: "Login - 登录",
  },
  // component: () => import('@/components/login'),
  component: () => import("@/components/login"),
};

export const mapRouter = {
  path: "/opdata",
  name: "opdata",
  meta: {
    title: "运维大数据",
  },
  component: () => import("@/pages/opsdata/info.vue"),
};

export const appRouter = [
  {
    path: "/",
    redirect: "/station",
  },
  {
    path: "/station",
    name: "station",
    component: Main,
    meta: {
      icon: "ios-home",
      title: "智慧站点",
      hideInMenu: false,
    },
    // redirect: "/station/list",
    children: [
      {
        path: "list",
        name: "station:list",

        meta: {
          title: "智慧站点",
          icon: "ios-list-box",
          hideInMenu: false,
        },
        component: () => import("@/pages/station/list"),
      },
      {
        path: "info",
        name: "station:info",
        meta: {
          title: "站点详情",
          hideInMenu: true,
        },
        component: () => import("@/pages/station/info"),
      },
    ],
  },
  {
    path: "/supervise",
    name: "supervise",
    component: Main,
    meta: {
      icon: "ios-home",
      title: "质量督查",
      hideInMenu: false,
    },
    // redirect: "/supervise/ops",
    children: [
      {
        path: "ops",
        name: "supervise:ops",
        component: RouterView,
        meta: {
          icon: "md-swap",
          title: "运维管理",
          hideInMenu: false,
        },
        // redirect: "/supervise/ops/opsgroup",
        children: [
          {
            path: "opsgroup",
            name: "supervise:ops:opsgroup",
            meta: {
              title: "运维事项分组",
              hideInMenu: false,
            },
            component: () => import("@/pages/supervise/ops/opsgroup"),
          },
          {
            path: "opslist",
            name: "supervise:ops:opslist",
            meta: {
              title: "运维事项列表",
              hideInMenu: true,
            },
            component: () => import("@/pages/supervise/ops/opslist"),
          },
          {
            path: "opsplan",
            name: "supervise:ops:opsplan",
            meta: {
              title: "运维计划",
              hideInMenu: false,
            },
            component: () => import("@/pages/supervise/ops/opsplan"),
          },
          {
            path: "opsrecord",
            name: "supervise:ops:opsrecord",
            meta: {
              title: "运维评分记录",
              hideInMenu: false,
            },
            component: () => import("@/pages/supervise/ops/opsrecord"),
          },
          {
            path: "calendar",
            name: "supervise:ops:calendar",
            meta: {
              title: "运维人员工作记录",
              hideInMenu: false,
            },
            component: () => import("@/pages/supervise/ops/calendar"),
          },
          {
            path: "opsrecord_detail",
            name: "supervise:ops:opsrecord_detail",
            meta: {
              title: "运维评分记录详情",
              hideInMenu: true,
            },
            component: () => import("@/pages/supervise/ops/opsrecord_detail"),
          },
          {
            path: "rectification",
            name: "supervise:ops:rectification",
            meta: {
              title: "运维整改",
              hideInMenu: false,
            },
            component: () => import("@/pages/supervise/ops/rectification"),
          },
          {
            path: "rectification_detail",
            name: "supervise:ops:rectification_detail",
            meta: {
              title: "运维整改详情",
              hideInMenu: true,
            },
            component: () =>
              import("@/pages/supervise/ops/rectification_detail"),
          },
          {
            path: "work_order",
            name: "supervise:ops:work_order",
            meta: {
              title: "运维工单",
              hideInMenu: false,
            },
            component: () => import("@/pages/supervise/ops/work_order"),
          },
          {
            path: "emergency",
            name: "supervise:ops:emergency",
            meta: {
              title: "应急处置",
              hideInMenu: false,
            },
            component: () => import("@/pages/supervise/ops/emergency"),
          },
          {
            path: "emergency_detail",
            name: "supervise:ops:emergency_detail",
            meta: {
              title: "应急处置详情",
              hideInMenu: true,
            },
            component: () => import("@/pages/supervise/ops/emergency_detail"),
          },
        ],
      },
      {
        path: "sup",
        name: "supervise:sup",
        component: RouterView,
        meta: {
          icon: "md-podium",
          title: "现场督查管理",
          hideInMenu: false,
        },
        children: [
          {
            path: "scene_config",
            name: "supervise:sup:scene_config",
            meta: {
              title: "现场督查配置(公司)",
              hideInMenu: false,
            },
            component: () => import("@/pages/supervise/sup/scene_config"),
          },
          {
            path: "examine_config",
            name: "supervise:sup:examine_config",
            meta: {
              title: "检查项配置(公司)",
              hideInMenu: true,
            },
            component: () => import("@/pages/supervise/sup/examine_config"),
          },
          {
            path: "scene_record",
            name: "supervise:sup:scene_record",
            meta: {
              title: "现场督查记录(公司)",
              hideInMenu: false,
            },
            component: () => import("@/pages/supervise/sup/scene_record"),
          },
          {
            path: "scene_detail",
            name: "supervise:sup:scene_detail",
            meta: {
              title: "现场督查详情(公司)",
              hideInMenu: true,
            },
            component: () => import("@/pages/supervise/sup/scene_detail"),
          },
          {
            path: "scene_rectify",
            name: "supervise:sup:scene_rectify",
            meta: {
              title: "现场督查整改项(公司)",
              hideInMenu: true,
            },
            component: () => import("@/pages/supervise/sup/scene_rectify"),
          },

          {
            path: "dept_scene_config",
            name: "supervise:dept_sup:dept_scene_config",
            meta: {
              title: "现场督查配置(部门)",
              hideInMenu: false,
            },
            component: () => import("@/pages/supervise/dept_sup/dept_scene_config"),
          },
          {
            path: "dept_examine_config",
            name: "supervise:dept_sup:dept_examine_config",
            meta: {
              title: "检查项配置(部门)",
              hideInMenu: true,
            },
            component: () => import("@/pages/supervise/dept_sup/dept_examine_config"),
          },
          {
            path: "dept_scene_record",
            name: "supervise:dept_sup:dept_scene_record",
            meta: {
              title: "现场督查记录(部门)",
              hideInMenu: false,
            },
            component: () => import("@/pages/supervise/dept_sup/dept_scene_record"),
          },
          {
            path: "dept_scene_detail",
            name: "supervise:dept_sup:dept_scene_detail",
            meta: {
              title: "现场督查详情(部门)",
              hideInMenu: true,
            },
            component: () => import("@/pages/supervise/dept_sup/dept_scene_detail"),
          },
          {
            path: "dept_scene_rectify",
            name: "supervise:dept_sup:dept_scene_rectify",
            meta: {
              title: "现场督查整改项(部门)",
              hideInMenu: true,
            },
            component: () => import("@/pages/supervise/dept_sup/dept_scene_rectify"),
          },
        ],
      },
    ],
  },
  {
    path: "/laboratory",
    name: "laboratory",
    component: Main,
    meta: {
      icon: "ios-home",
      title: "实验室",
      hideInMenu: false,
    },
    // redirect: "/laboratory/reagent",
    children: [
      {
        path: "reagent",
        name: "laboratory:reagent",
        meta: {
          title: "试剂申购单",
          icon: "ios-list-box",
          hideInMenu: false,
        },
        component: () => import("@/pages/laboratory/reagent"),
      },
      {
        path: "standard_solution",
        name: "laboratory:standard_solution",
        meta: {
          title: "标液申购单",
          icon: "ios-list-box",
          hideInMenu: false,
        },
        component: () => import("@/pages/laboratory/standard_solution"),
      },
      {
        path: "urgency",
        name: "laboratory:urgency",
        meta: {
          title: "紧急申购单",
          icon: "ios-list-box",
          hideInMenu: false,
        },
        component: () => import("@/pages/laboratory/urgency"),
      },
      {
        path: "declare",
        name: "laboratory:declare",
        meta: {
          title: "紧急申报记录",
          icon: "ios-list-box",
          hideInMenu: false,
        },
        component: () => import("@/pages/laboratory/declare"),
      },
      {
        path: "require",
        name: "laboratory:require",
        meta: {
          title: "领用日期管理",
          icon: "ios-list-box",
          hideInMenu: false,
        },
        component: () => import("@/pages/laboratory/require"),
      },
    ],
  },
  {
    path: "/spare",
    name: "spare",
    component: Main,
    meta: {
      icon: "ios-home",
      title: "备品备件",
      hideInMenu: false,
    },
    // redirect: "/spare/spareList",
    children: [
      {
        path: "spareList",
        name: "spare:spareList",
        meta: {
          title: "备品备件",
          icon: "ios-list-box",
          hideInMenu: false,
        },
        component: () => import("@/pages/spare/spareList"),
      },
    ],
  },
  {
    path: "/alarm",
    name: "alarm",
    component: Main,
    meta: {
      icon: "ios-home",
      title: "报警管理",
      hideInMenu: false,
    },
    // redirect: "/alarm/list",
    children: [
      {
        path: "list",
        name: "alarm:list",
        meta: {
          title: "报警管理",
          icon: "ios-list-box",
          hideInMenu: false,
        },
        component: (resolve) => require(["@/pages/alarm/list"], resolve),
      },
      {
        path: "listEp",
        name: "alarm:listEp",
        meta: {
          title: "报警管理(企业)",
          icon: "ios-list-box",
          hideInMenu: false,
        },
        component: (resolve) => require(["@/pages/alarm/listEp"], resolve),
      },
      {
        path: "listEpReport",
        name: "alarm:listEpReport",
        meta: {
          title: "报警日报(企业)",
          icon: "ios-list-box",
          hideInMenu: false,
        },
        component: (resolve) => require(["@/pages/alarm/listEpReport"], resolve),
      },
      {
        path: "listMonReport",
        name: "alarm:listMonReport",
        meta: {
          title: "报警月报(企业)",
          icon: "ios-list-box",
          hideInMenu: false,
        },
        component: (resolve) => require(["@/pages/alarm/listMonReport"], resolve),
      },
    ],
  },
  {
    path: "/statement",
    name: "statement",
    component: Main,
    meta: {
      icon: "ios-home",
      title: "数据报表",
      hideInMenu: false,
    },
    // redirect: "/statement/quality",
    children: [
      {
        path: "quality",
        name: "statement:quality",
        meta: {
          title: "现场督查报表",
          icon: "ios-list-box",
          hideInMenu: false,
        },
        component: () => import("@/pages/statement/quality"),
      },
      {
        path: "station",
        name: "statement:station",
        meta: {
          title: "站点报表",
          icon: "ios-list-box",
          hideInMenu: false,
        },
        component: () => import("@/pages/statement/station_list"),
      },
      {
        path: "effectiveRate",
        name: "statement:effectiveRate",
        meta: {
          title: "双率统计",
          icon: "ios-list-box",
          hideInMenu: false,
        },
        component: () => import("@/pages/statement/effectiveRate"),
      },
      {
        path: "single",
        name: "statement:single",
        meta: {
          title: "单站点分析",
          icon: "ios-list-box",
          hideInMenu: false,
        },
        component: () => import("@/pages/statement/single_station"),
      },
      {
        path: "water",
        name: "statement:water",
        meta: {
          title: "系统性水环境数据服务分析",
          icon: "ios-list-box",
          hideInMenu: false,
        },
        component: () => import("@/pages/statement/water_station"),
      },
      {
        path: "fault",
        name: "statement:fault",
        meta: {
          title: "故障报表",
          icon: "ios-list-box",
          hideInMenu: false,
        },
        component: () => import("@/pages/statement/fault_station"),
      },
      {
        path: "reagent_use",
        name: "statement:reagent_use",
        meta: {
          title: "申领报表",
          icon: "ios-list-box",
          hideInMenu: false,
        },
        component: () => import("@/pages/statement/reagent_use"),
      },
      {
        path: "newquality",
        name: "statement:newquality",
        meta: {
          title: "质量督查报表",
          icon: "ios-list-box",
          hideInMenu: false,
        },
        component: RouterView,
        children: [
          {
            path: "data_analysis",
            name: "statement:newquality:data_analysis",
            meta: {
              title: "数据分析",
              hideInMenu: false,
            },
            component: () => import("@/pages/statement/data_analysis"),
          },
          {
            path: "quality_detail",
            name: "statement:newquality:quality_detail",
            meta: {
              title: "报表明细",
              hideInMenu: false,
            },
            component: () => import("@/pages/statement/quality_detail"),
          },
        ],
      },
      {
        path: "reagent_statement",
        name: "statement:reagent_statement",
        meta: {
          title: "试剂报表",
          icon: "ios-list-box",
          hideInMenu: false,
        },
        component: RouterView,
        children: [
          {
            path: "reagent_state",
            name: "statement:reagent_statement:reagent_state",
            meta: {
              title: "数据分析",
              hideInMenu: false,
            },
            component: () => import("@/pages/statement/reagent_state"),
          },
          {
            path: "state_detail",
            name: "statement:reagent_statement:state_detail",
            meta: {
              title: "报表明细",
              hideInMenu: false,
            },
            component: () => import("@/pages/statement/statement_detail"),
          },
        ],
      },
      {
        path: "dataAnalysis",
        name: "statement:dataAnalysis",
        meta: {
          title: "站点目标管理数据分析报表",
          icon: "ios-list-box",
          hideInMenu: false,
        },
        component: () => import("@/pages/statement/dataAnalysis"),
      },
    ],
  },
  {
    path: "/monitor",
    name: "monitor",
    component: Main,
    meta: {
      icon: "ios-home",
      title: "在线监测",
      hideInMenu: false,
    },
    children: [
      {
        path: "monitorData",
        name: "system:onlineData:queryOnlineData",
        meta: {
          title: "在线监测数据",
          icon: "ios-list-box",
          hideInMenu: false,
        },
        component: () => import("@/pages/monitor/monitorData"),
      },
      {
        path: "monitorDataEp",
        name: "system:onlineData:queryOnlineDataEp",
        meta: {
          title: "在线监测数据(企业)",
          icon: "ios-list-box",
          hideInMenu: false,
        },
        component: () => import("@/pages/monitor/monitorDataEp"),
      },
    ],
  },
  {
    path: "/system",
    name: "system",
    component: Main,
    meta: {
      icon: "ios-home",
      title: "系统管理",
      hideInMenu: false,
    },
    // redirect: "/system/menu",
    children: [
      {
        path: "menu",
        name: "system:menu",
        meta: {
          title: "菜单管理",
          icon: "ios-list-box",
          hideInMenu: false,
        },
        component: () => import("@/pages/system/menu"),
      },
      {
        path: "organizational",
        name: "system:organizational",
        meta: {
          title: "组织架构管理",
          icon: "ios-list-box",
          hideInMenu: false,
        },
        component: RouterView,
        children: [
          {
            path: "dept",
            name: "system:organizational:dept",
            meta: {
              title: "部门管理",
              hideInMenu: false,
            },
            component: () => import("@/pages/system/organizational/dept"),
          },
          {
            path: "role",
            name: "system:organizational:role",
            meta: {
              title: "角色管理",
              hideInMenu: false,
            },
            component: () => import("@/pages/system/organizational/role"),
          },
          {
            path: "user",
            name: "system:organizational:user",

            meta: {
              title: "用户管理",
              hideInMenu: false,
              access: ["list", "add", "del", "edit", "access", "resetPwd"],
            },
            component: () => import("@/pages/system/organizational/user"),
          },
        ],
      },
      {
        path: "logs",
        name: "system:logs",
        meta: {
          title: "日志管理",
          icon: "ios-list-box",
          hideInMenu: false,
        },
        component: RouterView,
        children: [
          {
            path: "platform",
            name: "system:logs:platform",
            meta: {
              title: "平台操作日志",
              hideInMenu: false,
            },
            component: () => import("@/pages/system/logs/platform"),
          },
          {
            path: "device",
            name: "system:logs:device",
            meta: {
              title: "设备操作日志",
              hideInMenu: false,
            },
            component: () => import("@/pages/system/logs/device"),
          },
          {
            path: "login",
            name: "system:logs:login",
            meta: {
              title: "登录日志",
              hideInMenu: false,
            },
            component: () => import("@/pages/system/logs/login"),
          },
        ],
      },
      {
        path: "operation",
        name: "system:operation",
        meta: {
          title: "运维站点管理",
          icon: "ios-list-box",
          hideInMenu: false,
        },
        component: RouterView,
        children: [
          {
            path: "enterprise",
            name: "system:operation:enterprise",
            meta: {
              title: "企业管理",
              hideInMenu: false,
            },
            component: () => import("@/pages/system/operation/enterprise"),
          },
          {
            path: "operation_factory",
            name: "system:operation:operation_factory",
            meta: {
              title: "运维厂列表",
              hideInMenu: true,
            },
            component: () =>
              import("@/pages/system/operation/operation_factory"),
          },
          {
            path: "operation_site",
            name: "system:operation:operation_site",
            meta: {
              title: "运维站管理",
              hideInMenu: false,
            },
            component: () => import("@/pages/system/operation/operation_site"),
          },
          {
            path: "operation_library",
            name: "system:operation:operation_library",
            meta: {
              title: "备案资料",
              hideInMenu: true,
            },
            component: () =>
              import("@/pages/system/operation/operation_library"),
          },
        ],
      },

      {
        path: "instrument",
        name: "system:instrument",
        meta: {
          title: "数采仪管理",
          icon: "ios-list-box",
          hideInMenu: false,
        },
        component: RouterView,
        // redirect: "/system/instrument/instrument_list",
        children: [
          {
            path: "instrument",
            name: "system:instrument:list",
            meta: {
              title: "数采仪管理",
              hideInMenu: false,
            },
            component: () => import("@/pages/system/instrument/instrument"),
          },
          {
            path: "equipment",
            name: "system:instrument:equipment",

            meta: {
              title: "设备管理",
              hideInMenu: true,
            },
            component: () => import("@/pages/system/instrument/equipment"),
          },
        ],
      },
      {
        path: "alarmManage",
        name: "system:alarmManage",
        checked: false,
        meta: {
          title: "报警配置管理",
          icon: "ios-list-box",
          hideInMenu: false,
        },
        redirect: "/system/alarmManage/stationAlarmManageIndex",
        component: RouterView,
        children: [
          {
            path: "stationAlarmManageIndex",
            name: "system:alarmManage:stationAlarmManageIndex",
            checked: false,
            meta: {
              title: "报警规则",
              hideInMenu: true,
            },
            component: (resolve) =>
              require(["@/pages/system/alarmManage/indexInfo"], resolve),
          },
          {
            path: "configureAlarmRules",
            name: "system:alarmManage:configureAlarmRules",
            checked: false,
            meta: {
              title: "添加站点报警规则",
              hideInMenu: true,
            },
            component: () =>
              import("@/pages/system/alarmManage/configureAlarmRules"),
          },
        ],
      },
      {
        path: "library",
        name: "system:library",
        meta: {
          title: "知识库管理",
          icon: "ios-list-box",
          hideInMenu: false,
        },
        component: () => import("@/pages/system/library"),
      },
      {
        path: "modify",
        name: "system:modify",
        meta: {
          title: "数据修约",
          icon: "ios-list-box",
          hideInMenu: false,
        },
        component: () => import("@/pages/system/modify"),
      },
      {
        path: "standing_book",
        name: "system:standing_book",

        meta: {
          title: "台账管理",
          icon: "ios-list-box",
          hideInMenu: false,
        },
        component: RouterView,
        children: [
          {
            path: "reagent",
            name: "system:standing_book:reagent",
            meta: {
              title: "试剂台账",
              hideInMenu: false,
            },
            component: () => import("@/pages/system/standing_book/reagent"),
          },
          {
            path: "liquid",
            name: "system:standing_book:liquid",
            meta: {
              title: "标液台账",
              hideInMenu: false,
            },
            component: () => import("@/pages/system/standing_book/liquid"),
          },
          {
            path: "spare",
            name: "system:standing_book:spare",
            meta: {
              title: "备品备件台账",
              hideInMenu: false,
            },
            component: () => import("@/pages/system/standing_book/spare"),
          },
        ],
      },
      {
        path: "emission",
        name: "system:emission",
        meta: {
          title: "排放标准",
          icon: "ios-list-box",
          hideInMenu: false,
        },
        component: () => import("@/pages/system/emission"),
      },
      {
        path: "version",
        name: "system:version",
        meta: {
          title: "版本管理",
          icon: "ios-list-box",
          hideInMenu: false,
        },
        component: () => import("@/pages/system/version"),
      },
      {
        path: "dict_manage",
        name: "system:dict_manage",
        meta: {
          title: "字典管理",
          icon: "ios-list-box",
          hideInMenu: false,
        },
        component: RouterView,
        children: [
          {
            path: "dict",
            name: "system:dict_manage:dict",
            meta: {
              title: "设备字典管理",
              hideInMenu: false,
            },
            component: () => import("@/pages/system/dict_manage/dict"),
          },
        ],
      },
    ],
  },
];

//小程序端的权限
export const appWxRouter = [];

export const routes = [loginRouter, mapRouter, ...appRouter];
