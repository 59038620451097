export const suffix_photo_list = {
	'png': 1,
	'jpg': 2,
	'jpeg': 3,
	'bmp': 4,
	'gif': 5,
}

export const suffix_wps_list = {
	'mdb': 1,
	'xls': 2,
	'doc': 3,
	'docx': 4,
	'pdf': 5
}

export const message_text = {
  'first' :'已经是第一张了!',
  'last' : '这是最后一张了!'
}
