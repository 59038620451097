import Cookies from 'js-cookie'
import CryptoJS from 'crypto-js'
import { forEach, hasOneOf } from '@/libs/tools'
import { Message, Spin } from 'view-design'
import axios from 'axios'

export const TOKEN_KEY = 'token'

// 保存token
export const setToken = (token) => {
  Cookies.set(TOKEN_KEY, token, { expires: 30000 || 1 })
}

export const getToken = () => {
  const token = sessionStorage.getItem(TOKEN_KEY)
  if (token) return token
  else return false
}

export const cleanAll = () => {

  localStorage.clear()
  sessionStorage.clear()
  Cookies.remove('token')
  Cookies.remove('amap_ver')
  Cookies.remove('guid')
  Cookies.remove('key')
}

// 设置用户权限
export const setAccess = (access) => {
  sessionStorage.setItem('access', JSON.stringify(access))
}


export const getAccess = () => {
  //const access = Cookies.get('access')
  const access = sessionStorage.getItem('access')
  if (access) return JSON.parse(access)
  else return false
}

// export const setUserRoute = (list, access) => {
//   let res = []
//   forEach(list, item => {
//     if (access.includes(item.meta.title)) {
//       if (hasChild(item)) {
//         item.children = getMenuByRouter(item.children, access)
//       }
//       res.push(item)
//     }
//   })
//   return res
// }

export const routeDuplicate = (route) => {
  let [name, res] = [{}, []]
  route.map(val => {
    if (!name[val.name]) {
      name[val.name] = val
    } else {
      if (hasChild(val) && name[val.name].children && val.children) {
        name[val.name].children = routeDuplicate(name[val.name].children.concat(val.children))
      }
    }
  })
  for (let val in name) {
    res = res.concat(name[val])
  }
  return res.sort((a, b) => {
    return a.nodeKey - b.nodeKey
  })
}

export const adminMenu = (route) => {
  route.map(val => {
    val.checked = true
    if (val.children) {
      adminMenu(val.children)
    }
  })
  return route
}

export const setUserRoutes = (list) => {
  let res = []
  forEach(list, item => {
    if (item.checked || item.indeterminate) {
      if (hasChild(item)) {
        item.children = setUserRoutes(item.children)
      }
      res.push(item)
    }
  })
  return res
}

export const hasChild = (item) => {
  return item.children && item.children.length !== 0
}

export const inOf = (arr, targetArr) => {
  let res = true;
  arr.forEach(item => {
    if (targetArr.indexOf(item) < 0) {
      res = false;
    }
  });
  return res;
};

export const oneOf = (ele, targetArr) => {
  if (targetArr.indexOf(ele) >= 0) {
    return true;
  } else {
    return false;
  }
};


export const showThisMenuEle = (item, access) => {
  if (item.meta && item.meta.access && item.meta.access.length) {
    if (hasOneOf(item.meta.access, access)) return true
    else return false
  } else return true
}

//  通过路由列表得到菜单列表
export const getMenuByRouter = (list) => {
  let res = []

  forEach(list, item => {
    if (item.meta && !item.meta.hideInMenu) {
      let obj = {
        icon: (item.meta && item.meta.icon) || '',
        name: item.name,
        meta: item.meta,
        blank: item.blank ? item.blank : false
      }
      if (hasChild(item)) {
        obj.children = getMenuByRouter(item.children)
      } else {
        obj.children = []
      }
      res.push(obj)
    }
  })
  return res
}

/**
 * @param {Array} routeMetched 当前路由metched
 * @returns {Array}
 */
export const getBreadCrumbList = (routeMetched) => {
  let res = routeMetched.map(item => {
    let obj = {
      icon: (item.meta && item.meta.icon) || '',
      name: item.name,
      meta: item.meta
    }
    return obj
  })
  res = res.filter(item => {
    return !item.meta.hideInMenu
  })
  return [{
    name: 'home',
    to: '/home'
  }, ...res]
}

/**
 * @description 本地存储和获取标签导航列表
 */
export const setTagNavListInLocalstorage = list => {
  localStorage.tagNaveList = JSON.stringify(list)
}
/**
 * @returns {Array} 其中的每个元素只包含路由原信息中的name, path, meta三项
 */
export const getTagNavListFromLocalstorage = () => {
  const list = localStorage.tagNaveList
  return list ? JSON.parse(list) : []
}

/**
 * @param {Array} routers 路由列表数组
 * @description 用于找到路由列表中name为home的对象
 */
export const getHomeRoute = routers => {
  let i = -1
  let len = routers.length
  let homeRoute = {}
  while (++i < len) {
    let item = routers[i]
    if (item.children && item.children.length) {
      let res = getHomeRoute(item.children)
      if (res.name) return res
    } else {
      if (item.name === 'home') homeRoute = item
    }
  }
  return homeRoute
}

/**
 * @param {*} list 现有标签导航列表
 * @param {*} newRoute 新添加的路由原信息对象
 * @description 如果该newRoute已经存在则不再添加
 */
export const getNewTagList = (list, newRoute) => {
  const { name, path, meta } = newRoute
  let newList = [...list]
  if (newList.findIndex(item => item.name === name) >= 0) return newList
  else newList.push({ name, path, meta })
  return newList
}


/**
 * @param {*} access 用户权限数组，如 ['super_admin', 'admin']
 * @param {*} route 路由列表
 */
const hasAccess = (access, route) => {
  if (route.meta && route.meta.access) return hasOneOf(access, route.meta.access)
  else return true
}

/**
 * @param {*} name 即将跳转的路由name
 * @param {*} access 用户权限数组
 * @param {*} routes 路由列表
 * @description 用户是否可跳转到该页
 */
export const canTurnTo = (name, access, routes) => {
  const getHasAccessRouteNames = (list) => {
    let res = []
    list.forEach(item => {
      if (item.children && item.children.length) {
        res = [].concat(res, getHasAccessRouteNames(item.children))
      } else {
        if (item.meta && item.meta.access) {
          if (hasAccess(access, item)) res.push(item.name)
        } else {
          res.push(item.name)
        }
      }
    })
    return res
  }
  const canTurnToNames = getHasAccessRouteNames(routes)
  return canTurnToNames.indexOf(name) > -1
}

/**
 * @param {String} url
 * @description 从URL中解析参数
 */
export const getParams = url => {
  const keyValueArr = url.split('?')[1].split('&')
  let paramObj = {}
  keyValueArr.forEach(item => {
    const keyValue = item.split('=')
    paramObj[keyValue[0]] = keyValue[1]
  })
  return paramObj
}

/**
 * @param {Array} list 标签列表
 * @param {String} name 当前关闭的标签的name
 */
export const getNextName = (list, name) => {
  let res = ''
  if (list.length === 2) {
    res = 'home'
  } else {
    if (list.findIndex(item => item.name === name) === list.length - 1) res = list[list.length - 2].name
    else res = list[list.findIndex(item => item.name === name) + 1].name
  }
  return res
}


/**
 * AES 对称加密  
 * 
 */

export const aes = {
  stringToBytes(str) {
    let ch = []
    let st = []
    let re = []
    for (let i = 0; i < str.length; i++) {
      ch = str.charCodeAt(i)
      st = []
      do {
        st.push(ch & 0xFF)
        ch = ch >> 8
      }
      while (ch)
      re = re.concat(st.reverse())
    }
    return re
  },
  // AES 对称加密
  aesEncrypt(message, keyStr) {
    let key = CryptoJS.enc.Utf8.parse(keyStr);
    let meg = CryptoJS.enc.Utf8.parse(message)
    let encrypted = CryptoJS.AES.encrypt(meg, key, {
      // iv: key,
      mode: CryptoJS.mode.ECB,
      padding: CryptoJS.pad.Iso10126
    })
    return encrypted.toString()
  },
  // AES 对称解密
  aesDecrypt(message, keyStr) {
    let key = CryptoJS.enc.Utf8.parse(keyStr)
    let decrypted = CryptoJS.AES.decrypt(message, key, {
      // iv: key,
      mode: CryptoJS.mode.ECB,
      padding: CryptoJS.pad.Iso10126
    })
    return decrypted.toString(CryptoJS.enc.Utf8)
  },
  // 生成key
  newKey() {
    let guid = ''
    for (let i = 1; i <= 16; i++) {
      let n = Math.floor(Math.random() * 16.0).toString(16)
      guid += n
    }
    return guid
  }
}

export const stringToArray = (stringValue) => {
  try {
    if (stringValue) {
      return JSON.parse(stringValue);
    }
  } catch (e) {
    console.warn(`转JSON数组失败:${e.message}`, e);
  }
  return []
}

export const hasLength = (stringValue) => {
  if (stringValue) {
    if (stringValue.length > 0) {
      return true
    }
  }
  return false;
}

//验证手机号
export const validatePhone = (rule, value, callback) => {
  let reg = /^1[345789]\d{9}$/;
  if (!value) {
    callback('手机号不能为空')
  }
  if (value.trim() !== "" && !reg.test(value)) {
    callback("手机号格式不正确");
  } else {
    callback();
  }
};


export const setDeptTree = (menu) => {
  menu.map((val) => {
    val.label = val.name;
    val.value = val.deptId;
    if (val.children.length > 0) {
      setDeptTree(val.children);
    }
  });
}

export const setTree = (menu, name, value) => {
  menu.map((val) => {
    val.label = val[name];
    val.title = val[name]
    val.value = val[value];
    val.id = val[value]
    if (val.children.length > 0) {
      setTree(val.children, name, value);
    }
  });
}

export const on = (function () {
  if (document.addEventListener) {
    return function (element, event, handler, useCapture = false) {
      if (element && event && handler) {
        element.addEventListener(event, handler, useCapture);
      }
    };
  } else {
    return function (element, event, handler) {
      if (element && event && handler) {
        element.attachEvent('on' + event, handler);
      }
    };
  }
})();

/* istanbul ignore next */
export const off = (function () {
  if (document.removeEventListener) {
    return function (element, event, handler, useCapture = false) {
      if (element && event) {
        element.removeEventListener(event, handler, useCapture);
      }
    };
  } else {
    return function (element, event, handler) {
      if (element && event) {
        element.detachEvent('on' + event, handler);
      }
    };
  }
})();

// 导出
export const exportBlobFile = ((url, params, fileName) => {
  Spin.show()
  axios({
    url: process.env.VUE_APP_API_HOST + url,
    method: "post",
    data: params,
    headers: {
      satoken: getToken(),
    },
    responseType: "blob",
  })
    .then((res) => {
      if (res.status === 200) {
        Spin.hide()
        const blob = new Blob([res.data], {
          type: "application/vnd.ms-excel",
        });
        let link = document.createElement("a");
        link.href = URL.createObjectURL(blob);
        link.setAttribute("download", fileName);
        link.click();
        link = null;
        Message.success("导出成功")
      } else {
        Message.error("导出失败")
      }
    }).catch(err => {
      console.log(err);
    })
})



// 防抖
/**
 *  防抖
 * @param {Function} func 要执行的回调函数
 * @param {Number} wait 延时的时间
 * @param {Boolean} immediate 是否立即执行
 */
let timeout
export function debounce(func, wait = 300, immediate = false) {
  //  移除定时器
  if (timeout !== null) {
    clearTimeout(timeout)
  }
  //  是否立即执行
  if (immediate) {
    //  立即执行
    const callNow = !timeout
    timeout = setTimeout(() => {
      timeout = null
    }, wait)
    if (callNow) typeof func === 'function' && func()
  } else {
    //  设置定时器，当最后一次操作后，timeout不会再被清除，所以在延时wait毫秒后执行func回调方法
    timeout = setTimeout(() => {
      typeof func === 'function' && func()
    }, wait)
  }
}
/**
 * 节流
 * @param {Function} func 要执行的回调函数
 * @param {Number} wait 延时的时间
 * @param {Boolean} immediate 是否立即执行
 */
let timer, flag
export function throttle(func, wait = 3000, immediate = true) {
  //  是否立即执行
  if (immediate) {
    //  立即执行
    if (!flag) {
      flag = true
      typeof func === 'function' && func()
      timer = setTimeout(() => {
        flag = false
      }, wait)
    }
  } else {
    if (!flag) {
      flag = true
      timer = setTimeout(() => {
        flag = false
        typeof func === 'function' && func()
      }, wait)
    }
  }
}

// 地图点位图标
export const setColorMap = (arrData) => {
  arrData.forEach(item => {
    Object.assign(item, {
      background: item.onlineStatus,
    });
  })
  return arrData
}

export const uuid = (len, radix) => {
  var chars = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz'.split('');
  var uuid = [],
    i;
  radix = radix || chars.length;

  if (len) {
    // Compact form
    for (i = 0; i < len; i++) uuid[i] = chars[0 | Math.random() * radix];
  } else {
    // rfc4122, version 4 form
    var r;

    // rfc4122 requires these characters
    uuid[8] = uuid[13] = uuid[18] = uuid[23] = '-';
    uuid[14] = '4';

    // Fill in random data.  At i==19 set the high bits of clock sequence as
    // per rfc4122, sec. 4.1.5
    for (i = 0; i < 36; i++) {
      if (!uuid[i]) {
        r = 0 | Math.random() * 16;
        uuid[i] = chars[(i == 19) ? (r & 0x3) | 0x8 : r];
      }
    }
  }

  return uuid.join('');
}
// 删掉为null或数组为空的字段
export const delEmptyQueryNodes = (arr) => {
  arr.forEach(obj => {
    Object.assign(obj, { align: "center" },);
    Object.keys(obj).forEach((key) => {
      let value = obj[key];
      value && typeof value === 'object' && delEmptyQueryNodes(value);
      (value === '' || value === null || value === undefined || value.length === 0 || Object.keys(value).length === 0) && delete obj[key];
    });
    return obj;
  })
  return arr
};
