import request from '../request'
export default {
    // 根据设备ID查询设备的监测信息
    mqttGetDeviceInfo: (equipmentId) => {
        return request({
            url: `/mqtt/mqtt/getDeviceInfo/${equipmentId}`,
            method: 'post'
        })
    },
     // 根据设备ID查询设备的kbvalue值
     mqttKblist: (data) => {
        return request({
            url: `/mqtt/mqtt/kblist`,
            data,
            method: 'post'
        })
    },
     // 推送命令给数采仪
     mqttCommand: (data) => {
        return request({
            url: `/mqtt/mqtt/command`,
            data,
            method: 'post'
        })
    },
     // 设备日志
     equipmentLogList: (data) => {
        return request({
            url: `/mqtt/equipmentLog/list`,
            data,
            method: 'post'
        })
    },
     // MQTT日志
     mqttLogList: (data) => {
        return request({
            url: `/mqtt/mqttLog/list`,
            data,
            method: 'post'
        })
    },
}