import request from '../request'

export default {
  // 试剂报表-统计
  statTotal: (data) => {
    return request({
      url: `/system/lib/reagentapplyinfo/count`,
      method: 'post',
      data
    })
  },
  // 试剂申报报表
  reagentData: (data) => {
    return request({
      url: `/system/lib/reagentapplyinfo/reportform`,
      method: 'post',
      data
    })
  },
  // 标液申报报表
  solutionData: (data) => {
    return request({
      url: `/system/lib/solutionapplyinfo/reportform`,
      method: 'post',
      data
    })
  },
  // 标液申报报表
  waterData: (data) => {
    return request({
      url: `/system/lib/watertestingapplyinfo/reportform`,
      method: 'post',
      data
    })
  },
  // 试剂类型下拉
  reagentRef: () => {
    return request({
      url: `/system/reagentLedger/types`,
      method: 'post',
    })
  },
  // 试剂分析报表-试剂统计
  analysisCount: (data) => {
    return request({
      url: `/system/lib/reagentapplyinfo/analysis/count`,
      method: 'post',
      data
    })
  },
  // 试剂分析报表-试剂使用趋势
  analysisUseRate: (data) => {
    return request({
      url: `/system/lib/reagentapplyinfo/analysis/useRate`,
      method: 'post',
      data
    })
  },
  // 试剂分析报表-试剂趋势分析
  analysisRegent: (data) => {
    return request({
      url: `/system/lib/reagentapplyinfo/analysis/regent`,
      method: 'post',
      data
    })
  },
  // 标液分析报表-标液统计
  analysisSoluCount: (data) => {
    return request({
      url: `/system/lib/solutionapplyinfo/analysis/count`,
      method: 'post',
      data
    })
  },
  // 试剂分析报表-top10
  analysisTopten: (data) => {
    return request({
      url: `/system/lib/reagentapplyinfo/analysis/topten`,
      method: 'post',
      data
    })
  },
  // 试剂分析报表-试剂超期更换分析
  analysisOverdue: (data) => {
    return request({
      url: `/system/lib/reagentapplyinfo/analysis/overdue`,
      method: 'post',
      data
    })
  },
  // 试剂分析报表-试剂申报明细
  analysisDetailRea: (data) => {
    return request({
      url: `/system/lib/reagentapplyinfo/analysis/detail`,
      method: 'post',
      data
    })
  },
  // 标液分析报表-明细
  analysisDetailSol: (data) => {
    return request({
      url: `/system/lib/solutionapplyinfo/analysis/detail`,
      method: 'post',
      data
    })
  },
  // 水样分析报表-明细
  analysisDetailWater: (data) => {
    return request({
      url: `/system/lib/watertestingapplyinfo/analysis/detail`,
      method: 'post',
      data
    })
  },
  // 根据字典类型查询字典数据列表
  tpyeRef: (dictType) => {
    return request({
      url: `/system/dictData/queryDictData/${dictType}`,
      method: 'post',
    })
  },
  // 部门故障次数统计
  faultCount: (data) => {
    return request({
      url: `/system/faultStatistics/countStatistics`,
      method: 'post',
      data
    })
  },
  // 部门故障次数趋势
  faultTrend: (data) => {
    return request({
      url: `/system/faultStatistics/countTrend`,
      method: 'post',
      data
    })
  },
  // 部门故障率趋势
  faultRateTrend: (data) => {
    return request({
      url: `/system/faultStatistics/rateTrend`,
      method: 'post',
      data
    })
  },
  // 部门设备故障率和平均修复时间统计
  faultRates: (data) => {
    return request({
      url: `/system/faultStatistics/rateStatistics`,
      method: 'post',
      data
    })
  },
  // 部门平均修复时间趋势
  faultRepaire: (data) => {
    return request({
      url: `/system/faultStatistics/repairTrend`,
      method: 'post',
      data
    })
  },
  // 数据异常现象占比和故障类型统计
  faultStrip: (data) => {
    return request({
      url: `/system/faultStatistics/stripStatistics`,
      method: 'post',
      data
    })
  },
  // 根据检查单元查询相关统计
  faultUnit: (data) => {
    return request({
      url: `/system/faultStatistics/statisticsByDetectionUnit`,
      method: 'post',
      data
    })
  },
  // 设备故障率&平均修复时间列表统计
  faultRateList: (data) => {
    return request({
      url: `/system/faultStatistics/rateList`,
      method: 'post',
      data
    })
  },
  // 设备故障率&平均修复时间列表统计趋势
  faultRateTime: (data) => {
    return request({
      url: `/system/faultStatistics/rateListTrend`,
      method: 'post',
      data
    })
  },
  // 质量督查报表-报表分析-人员平均效率分析
  personAvg: (data) => {
    return request({
      url: `/system/maintenance/bigdata/supervision/data/personavg`,
      method: 'post',
      data
    })
  },
  // 质量督查报表-报表分析-平均效率趋势
  personAvgTrend: (data) => {
    return request({
      url: `/system/maintenance/bigdata/supervision/data/personavgtrend`,
      method: 'post',
      data
    })
  },
  // 质量督查报表-报表分析-综合运维指数统计分析
  opAnalyse: (data) => {
    return request({
      url: `/system/maintenance/bigdata/supervision/data/operationindex`,
      method: 'post',
      data
    })
  },
  // 质量督查报表-报表分析-站点运维质量TOP10
  opQuality: (data) => {
    return request({
      url: `/system/maintenance/bigdata/supervision/data/station10`,
      method: 'post',
      data
    })
  },
  rateQueryList: (data) => {
    return request({
      url: `/system/rate/queryList`,
      method: 'post',
      data
    })
  },
  rateExport: (data) => {
    return request({
      url: `/system/rate/export`,
      method: 'post',
      data,
      responseType: "blob",
    })
  },
  
}