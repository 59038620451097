/*
 * @Author: winbin
 * @Date: 2022-08-03 10:09:31
 * @LastEditors: winbin
 * @LastEditTime: 2023-02-01 10:05:48
 * @FilePath: \jx2qi\src\libs\router.js
 * @Description: Desc
 */
import Vue from "vue";
import Router from "vue-router";
import ViewUI from "view-design";
import store from "@/libs/store";
import { routes } from "@/libs/routeList";

Vue.use(Router);

const router = new Router({
  // mode:'history',
  routes,
});

const routerPush = Router.prototype.push;

Router.prototype.push = function push(location) {
  return routerPush.call(this, location).catch((error) => error);
};

const whiteList = ["login"];
const nameWhiteList = process.env.VUE_APP_WHITE_LIST

const hasAccess = (access, acc) => {
  const name = sessionStorage.getItem("userName");
  return nameWhiteList.includes(name) || access.includes(acc);
};

router.beforeEach((to, from, next) => {
  ViewUI.LoadingBar.start();
  let token = sessionStorage.getItem("token");
  let access = JSON.parse(sessionStorage.getItem("access"));

  if (whiteList.includes(to.name) || token) {
    if (to.name == "login") {
      next();
    } else if (hasAccess(access, to.name)) {
      next();
      let tabList = store.getters.tabList;
      let hasTab = tabList.some((val) => {
        return val.name == to.name;
      });
      if (!hasTab && to.name !== "login") {
        tabList.push({
          name: to.name,
          path: to.path,
          title: to.meta.title,
          query: to.query,
        });
        store.commit("upState", [
          {
            key: "tabList",
            val: tabList,
          },
        ]);
      }

      store.commit("upState", [
        {
          key: "actTab",
          val: to.name,
        },
      ]);
    } else {
      ViewUI.Message.error("暂无权限,请联系管理员开通");
      ViewUI.LoadingBar.finish();
    }
  } else {
    next({
      path: "/login",
    });
  }
});

router.afterEach((to) => {
  ViewUI.LoadingBar.finish();
  window.scrollTo(0, 0);
});

export default router;
