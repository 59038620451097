import request from '../request'

export default {
  // 运维工单 - 列表查询
  opMaintenanceList: (data) => {
    return request({
      url: `/system/opMaintenanceOrder/list`,
      method: 'post',
      data
    })
  },
  // 运维工单 - 详情
  opMaintenanceDetail: (id) => {
    return request({
      url: `/system/opMaintenanceOrder/info/${id}`,
      method: 'post',
    })
  },
  // 运维工单 - 删除
  opMaintenanceDelete: (id) => {
    return request({
      url: `/system/opMaintenanceOrder/delete/${id}`,
      method: 'post',
    })
  },
  // 现场督查配置 - 查询
  opSupervisionList: (data) => {
    return request({
      url: `/system/opSiteSupervisionVersion/search`,
      method: 'post',
      data
    })
  },
  // 现场督查配置 - 新增
  opSupervisionAdd: (data) => {
    return request({
      url: `/system/opSiteSupervisionVersion/add`,
      method: 'post',
      data
    })
  },
  // 现场督查配置 - 删除
  opSupervisionDel: (id) => {
    return request({
      url: `/system/opSiteSupervisionVersion/delete/${id}`,
      method: 'post',
    })
  },
  // 现场督查配置 - 修改
  opSupervisionUpdate: (data) => {
    return request({
      url: `/system/opSiteSupervisionVersion/update`,
      method: 'post',
      data
    })
  },
  // 现场督查配置 - 详情
  opSupervisionInfo: (id) => {
    return request({
      url: `/system/opSiteSupervisionVersion/info/${id}`,
      method: 'post',
    })
  },
  // 现场督查配置 - 停用
  opSupervisionDis: (id) => {
    return request({
      url: `/system/opSiteSupervisionVersion/disable/${id}`,
      method: 'post',
    })
  },
  // 现场督查配置 - 启用
  opSupervisionEn: (id) => {
    return request({
      url: `/system/opSiteSupervisionVersion/enable/${id}`,
      method: 'post',
    })
  },
  // 检查项配置 - 事项分类下拉
  opSupervisionItemType: (type) => {
    return request({
      url: `/system/opSiteSupervisionItem/typelist?type=${type}`,
      method: 'post',
    })
  },
  // 检查项配置 - 查询
  opSupervisionItemList: (data) => {
    return request({
      url: `/system/opSiteSupervisionItem/search`,
      method: 'post',
      data,
    })
  },
  // 检查项配置 - 详情
  opSupervisionItemInfo: (id) => {
    return request({
      url: `/system/opSiteSupervisionItem/info/${id}`,
      method: 'post',
    })
  },
  // 检查项配置 - 新增
  opSupervisionItemAdd: (data) => {
    return request({
      url: `/system/opSiteSupervisionItem/add`,
      method: 'post',
      data,
    })
  },
  // 检查项配置 - 修改
  opSupervisionItemUpdate: (id, data) => {
    return request({
      url: `/system/opSiteSupervisionItem/update/${id}`,
      method: 'post',
      data,
    })
  },
  // 检查项配置 - 删除
  opSupervisionItemDel: (id) => {
    return request({
      url: `/system/opSiteSupervisionItem/delete/${id}`,
      method: 'post',
    })
  },
  // 现场督查检查事项选项 - 新增
  opSupervisionItemInfoAdd: (data) => {
    return request({
      url: `/system/opSiteSupervisionItemInfo/addbatch`,
      method: 'post',
      data,
    })
  },
  // 现场督查检查事项选项 - 删除
  opSupervisionItemInfoDel: (data) => {
    return request({
      url: `/system/opSiteSupervisionItemInfo/deletebatch`,
      method: 'post',
      data
    })
  },
  // 现场督查检查事项选项 - 修改
  opSupervisionItemInfoUpdate: (data) => {
    return request({
      url: `/system/opSiteSupervisionItemInfo/update`,
      method: 'post',
      data,
    })
  },
  // 现场督查记录 - 查询
  opSupervisionRecordList: (data) => {
    return request({
      url: `/system/opSiteSupervisionRecord/search`,
      method: 'post',
      data,
    })
  },
  // 现场督查记录 - 详情
  opSupervisionRecordDetail: (id) => {
    return request({
      url: `/system/opSiteSupervisionRecord/searchdetail/${id}`,
      method: 'post',
    })
  },
  // 现场督查记录 - 查看整改项
  opSupervisionRecordCor: (recordId) => {
    return request({
      url: `/system/opSiteSupervisionRecord/queryCorrective/${recordId}`,
      method: 'post',
    })
  },
  // 现场督查统计
  opSupervisionRecordCount: (data) => {
    return request({
      url: `/system/opSiteSupervisionRecord/count`,
      method: 'post',
      data,
    })
  },
  // 按检查项统计
  opSupervisionCount: (data) => {
    return request({
      url: `/system/opSiteSupervisionRecord/supervisioncount`,
      method: 'post',
      data,
    })
  },
  // 应急处置列表查询
  opEmergencyList: (data) => {
    return request({
      url: `/system/opEmergencyDisposal/list`,
      method: 'post',
      data,
    })
  },
  // 应急处置详情
  opEmergencyDetail: (id) => {
    return request(`/system/opEmergencyDisposal/info/${id}`, {
      method: 'post',
    })
  },
  // 现场督查扣分占比分析
  superScore: (data) => {
    return request({
      url: `/system/supervisionStatistics/supervisionScore`,
      method: 'post',
      data,
    })
  },
  // 现场督查统计
  superStatis: (data) => {
    return request({
      url: `/system/supervisionStatistics/supervisionStatistics`,
      method: 'post',
      data,
    })
  },
  // 现场督查统计趋势
  superTrend: (data) => {
    return request({
      url: `/system/supervisionStatistics/supervisionStatisticsTrend`,
      method: 'post',
      data,
    })
  },
}