<template>
  <div class="layout-main">
    <div class="layout-header">
      <div class="logo">
        <img :src="logoSrc" />
        <b>临江河运维</b>
      </div>
      <Menu
        mode="horizontal"
        class="header-menu"
        theme="light"
        :active-name="actTopMenu"
        @on-select="selectTopMenu"
      >
        <MenuItem v-for="val in accMenu" :key="val.name" :name="val.name">{{
          val.meta.title
        }}</MenuItem>
      </Menu>

      <div class="infos">
        <Tooltip content="智慧大屏">
          <Icon type="ios-options" class="icons" @click="toOpdata" />
        </Tooltip>
        <Tooltip content="关于系统" style="margin-left: 20px">
          <Icon type="ios-alert-outline" class="icons" @click="getVersion" />
        </Tooltip>
        <Dropdown style="margin-left: 20px" placement="bottom-end">
          <div class="info">
            <Icon type="md-contact" size="28" />
            {{ userName }},欢迎你
          </div>
          <DropdownMenu slot="list">
            <Button type="text" @click="handleOut">退出登录</Button>
          </DropdownMenu>
        </Dropdown>
      </div>
    </div>
    <div class="layout-center">
      <div class="layout-sider" :class="extended ? 'extend' : ''">
        <div class="sider-btn" @click="extended = !extended">
          <Icon v-if="extended" type="ios-arrow-back" />
          <Icon v-else type="ios-arrow-forward" />
        </div>
        <side-menu :menuList="sideMenuList" :extend="extended"></side-menu>
      </div>
      <div class="layout-content">
        <Tabs
          :value="actTab"
          type="card"
          class="card"
          closable
          @on-click="clickTab"
          @on-tab-remove="removeTab"
        >
          <TabPane
            v-for="val in tabList"
            :key="val.path"
            :label="val.title"
            :name="val.name"
          ></TabPane>
        </Tabs>
        <keep-alive :include="routers">
          <router-view class="router-view"></router-view>
        </keep-alive>
      </div>
    </div>
    <Modal v-model="showVersion" title="关于系统">
      <div style="text-align: center">
        <img style="width: 100px" :src="logoSrc" />
        <p>临江河在线运维平台</p>
        <p>版本: {{ versionInfo.versionCode }}</p>
        <p>更新时间: {{ versionInfo.updateTime }}</p>
      </div>
      <b style="display:block;margin-top: 20px; ">新版特性：</b>
      <div style="height: 200px; overflow-y: auto">
        <pre>{{ versionInfo.content }}</pre>
      </div>
      <div slot="footer"></div>
    </Modal>
  </div>
</template>
<script>
import sideMenu from "@/components/SideMenu";
import { mapGetters, mapActions } from "vuex";
import { setBread } from "@/libs/tools";
import { alarmNum } from "@/libs/api";
const whiteList = process.env.VUE_APP_WHITE_LIST;
console.log(whiteList);
export default {
  name: "home",
  data() {
    return {
      actTopMenu: "index",
      actSideMenu: "",
      extended: true,
      nameWhiteList: whiteList,
      userName: sessionStorage.getItem("userName"),
      breadcrumb: JSON.parse(sessionStorage.getItem("breadcrumb")),
      home: "home",
      showVersion: false,
      versionInfo: {
        clientType: "PC",
        content: "",
        createTime: "",
        remark: "",
        updateTime: "",
        versionCode: "",
      },
    };
  },
  components: {
    sideMenu,
  },
  watch: {
    "$route.matched": {
      handler: function (value) {
        this.actTopMenu = this.$route.matched[0].name;
      },
      immediate: true,
    },
  },
  computed: {
    ...mapGetters(["menuList", "tabList"]),
    actTab() {
      return this.$store.state.app.actTab;
    },
    theme() {
      return this.$store.state.app.theme;
    },
    title() {
      return sessionStorage.getItem("appTitle");
    },
    logoSrc() {
      return require(`@/assets/img/logo.png`);
    },
    sideMenuList() {
      let side = this.menuList.find((val) => {
        return val.name == this.actTopMenu;
      });
      if (side && side.children) {
        return side.children;
      }
    },
    accMenu() {
      let name = sessionStorage.getItem("userName");
      return this.nameWhiteList.includes(name)
        ? this.menuList
        : this.getMenu(this.menuList);
    },
    access() {
      return JSON.parse(sessionStorage.getItem("access"));
    },
    routers() {
      let rt = [];
      this.tabList.map((val) => {
        rt.push(val.name);
      });
      console.log(rt);
      return rt;
    },
  },
  methods: {
    ...mapActions(["handleLogOut"]),
    getMenu(menu) {
      let menus = [];
      menu.map((val) => {
        if (this.hasAccess(val.name)) {
          let info = val;
          if (val.children) {
            val.children = this.getMenu(val.children);
          }
          menus.push(info);
        }
      });
      return menus;
    },
    hasAccess(acc) {
      return this.access.includes(acc);
    },
    handleOut() {
      this.handleLogOut().then(
        this.$router.push({
          name: "login",
        })
      );
    },
    selectTopMenu(name) {
      if (this.actTopMenu !== name) {
        this.actTopMenu = name;
        this.$store.commit("upState", [
          {
            key: "tabList",
            val: [],
          },
        ]);
        console.log(this.sideMenuList);
        if (this.sideMenuList[0].children.length > 0) {
          this.$router.push({
            name: this.sideMenuList[0].children[0].name,
          });
        } else {
          this.$router.push({
            name: this.sideMenuList[0].name,
          });
        }
      }
    },
    clickTab(name) {
      let route = this.tabList.find((val) => {
        return val.name == name;
      });
      this.$router.push(route);
    },
    removeTab(name) {
      this.$store.commit("upState", [
        {
          key: "tabList",
          val: this.tabList.filter((val) => {
            return val.name !== name;
          }),
        },
      ]);
      if (name == this.actTab) {
        this.$router.push(this.tabList.slice(-1)[0].path);
      }
    },
    toOpdata() {
      this.$router.push("/opdata");
    },
    getVersion() {
      this.$api.versionRecordNow("PC").then((res) => {
        console.log(res);

        this.versionInfo = res.data;
        this.showVersion = true;
      });
    },
  },
  created() {
    console.log(this.routers);
    console.log(this.$route);
    // setBread(this.$route.matched);
    // window.addEventListener("setItem", () => {
    //   this.breadcrumb = JSON.parse(sessionStorage.getItem("breadcrumb"));
    // });
  },
};
</script>
<style lang="less" scoped>
.layout-main {
  position: relative;
  width: 100%;
  height: 100%;
  background: #f2f2f2;
  .layout-header {
    display: flex;
    position: absolute;
    align-items: center;
    justify-content: space-between;
    top: 0;
    left: 0;
    width: 100%;
    height: 60px;
    padding: 0 20px;
    background: #fff;
    box-shadow: 0px 4px 10px rgba(78, 89, 105, 0.06);
    z-index: 999;
    .logo {
      width: 250px;
      height: 50px;
      display: flex;
      align-items: center;
      img {
        height: 100%;
      }
      b {
        color: #000;
        font-size: 18px;
      }
    }
    .header-menu {
      flex: 1;
    }
    .infos {
      display: flex;
      align-items: center;
      .info {
        display: flex;
        align-items: center;
        i {
          color: #53af3b;
        }
      }
      .icons {
        font-size: 20px;
        font-weight: bold;
        cursor: pointer;
      }
    }
  }
  .layout-center {
    display: flex;
    height: 100%;
    width: 100%;
    padding-top: 60px;
    .layout-sider {
      position: relative;
      height: 100%;
      width: 80px;
      background: #fff;
      transition: all 0.5s;
      &.extend {
        width: 200px;
      }
      .sider-btn {
        position: absolute;
        top: 50%;
        margin-top: -25px;
        z-index: 999;
        background: #fff;
        right: 0;
        height: 50px;
        width: 25px;
        line-height: 50px;
        font-size: 24px;
        text-align: center;
        box-shadow: -2px 1px 5px 1px #ccc;
        border-radius: 5px 0 0 5px;
        cursor: pointer;
        &:hover {
          color: #53af3b;
          box-shadow: -2px 1px 5px #53af3b50;
        }
      }
    }
    .layout-content {
      display: flex;
      flex-direction: column;
      flex: 1;
      width: calc(100% - 200px);
      padding: 10px;
      .router-view {
        flex: 1;
        width: 100%;
        padding: 20px;
        background: #fff;
        overflow-y: auto;
      }
    }
  }
}
</style>
