// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import Vue from "vue";
import App from "./App";
import ViewUI from "view-design";
import router from "@/libs/router";
import store from "@/libs/store";
import "jsencrypt";
import { aes } from "@/libs/util";
import axios from "axios";

import api from "@/libs/api";

import G2 from "@antv/g2";
import { DatePicker } from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
import moment from "moment";
import jsmind from "jsmind";

import { debounce, throttle } from "@/libs/util";


import AMapLoader from "@amap/amap-jsapi-loader";
window._AMapSecurityConfig = {
  securityJsCode: "ce1c6742ea97de33510f244378be41ee",
};

// sentry
import * as Sentry from "@sentry/browser";
import { Integrations } from "@sentry/tracing";

Sentry.init({
  Vue,
  dsn: "https://d5516ebe5d9c42758f93f2cf58bc08ec@sentry.cqhtwl.com.cn/6",
  autoSessionTracking: true,
  integrations: [
    new Integrations.BrowserTracing({
      tracePropagationTargets: [
        "localhost",
        "jx2test.cqhtwl.com.cn",
        "zxyw.jiaxing-cloud.com",
      ],
    }),
  ],

  // We recommend adjusting this value in production, or using tracesSampler
  // for finer control
  tracesSampleRate: 1.0,
  trackComponents: ["Header", "Navigation", "Footer"],
  hooks: ["create", "mount"],
  environment: process.env.NODE_ENV,
});
// sentry end

Vue.prototype.$encrypt = new JSEncrypt();
// Vue.prototype.$encrypt.setPublicKey(process.env.VUE_APP_RSA_PUBLIC_KEY)
// Vue.prototype.$encrypt.setPrivateKey(process.env.VUE_APP_RSA_PRIVATE_KEY)
Object.assign(Vue.prototype.$encrypt, aes);

Vue.prototype.$http = axios;
Vue.prototype.$api = api;
Vue.prototype.$g2 = G2;
Vue.prototype.$debounce = debounce;
Vue.prototype.$throttle = throttle;
Vue.prototype.$jsmind = jsmind;

AMapLoader.load({
  key: "4cf63ada6bd16696a9f0059acf0aba0d",
  version: "2.0",
  plugins: [
    "Map3D",
    "ElasticMarker",
    "AMap.PlaceSearch",
    "AMap.MarkerCluster",
    "AMap.Geocoder",
  ],
  AMapUI: {
    version: "1.1",
    plugins: ["overlay/SimpleMarker"],
  },
})

Vue.prototype.$moment = moment;
import "view-design/dist/styles/iview.css";
import "./assets/theme/green.less";
import "./assets/style/common.less";
import "jsmind/style/jsmind.css";

sessionStorage.setItem("appTitle", process.env.VUE_APP_TITLE);

Vue.prototype.resetSetItem = function(key, newVal) {
  // 创建一个StorageEvent事件
  var newStorageEvent = document.createEvent("StorageEvent");
  const storage = {
    setItem: function(k, val) {
      sessionStorage.setItem(k, val);
      // 初始化创建的事件
      newStorageEvent.initStorageEvent(
        "setItem",
        false,
        false,
        k,
        null,
        val,
        null,
        null
      );

      // 派发对象
      window.dispatchEvent(newStorageEvent);
    },
  };
  return storage.setItem(key, newVal);
};

Vue.use(ViewUI);
Vue.config.productionTip = false;
Vue.use(DatePicker);


import imagePreview from '@/components/imagePreview'
Vue.use(imagePreview)

const nameWhiteList = process.env.VUE_APP_WHITE_LIST;

const checkAccess = (el, binding) => {
  const user = sessionStorage.getItem("userName");
  const access = sessionStorage.getItem("access");
  const { value } = binding;
  if ((!value || !access.includes(value)) && !nameWhiteList.includes(user)) {
    console.log(access, value);
    el.parentNode && el.parentNode.removeChild(el);
  }
};

Vue.directive("hasAccess", {
  inserted(el, binding) {
    checkAccess(el, binding);
  },
  update(el, binding) {
    checkAccess(el, binding);
  },
});

/* eslint-disable no-new */
window.$vm = new Vue({
  el: "#app",
  router,
  store,
  components: { App },
  template: "<App/>",
});
