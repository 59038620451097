<template>
  <div class="side-menu" :class="extend ? 'extend' : ''">
    <!-- 侧边栏菜单 -->
    <!-- :open-names="currentOpen" -->
    <Menu
      :active-name="current"
      :theme="theme"
      width="180px"
      :open-names="[currentOpen]"
      v-if="extend"
    >
      <template v-for="menu in menuList">
        <MenuItem
          v-if="menu.children.length == 1"
          :name="menu.children[0].name"
          :key="'menuitem' + menu.children[0].name"
          @click.native="
            changePath(menu.children[0].name, menu.children[0].blank)
          "
        >
          <Icon
            :type="menu.children[0].icon || menu.icon || 'ios-list-box'"
            :key="'menuicon' + menu.children[0].name"
          ></Icon>
          <span
            class="layout-text"
            :key="'title' + menu.name"
            :itle="menu.children[0].meta.title"
            >{{ menu.children[0].meta.title }}</span
          >
        </MenuItem>
        <MenuItem
          v-else-if="menu.children.length == 0"
          :name="menu.name"
          :key="'menuitem' + menu.name"
          @click.native="changePath(menu.name, menu.blank)"
        >
          <Icon
            :type="menu.icon || 'ios-list-box'"
            :key="'menuicon' + menu.name"
          ></Icon>
          <span
            class="layout-text"
            :key="'title' + menu.name"
            :itle="menu.meta.title"
            >{{ menu.meta.title }}</span
          >
        </MenuItem>
        <Submenu :name="menu.name" v-else>
          <template slot="title">
            <Icon :type="menu.icon" :key="'menuicon' + menu.name"></Icon>
            {{ menu.meta.title }}
          </template>
          <MenuItem
            v-for="option in menu.children"
            :name="option.name"
            :key="option.name"
            @click.native="changePath(option.name, option.blank)"
          >
            <!-- <Icon :type="option.icon || menu.icon" :key="'menuicon' + option.name"></Icon> -->
            <Icon type="ios-git-commit" />
            <span :key="'title' + menu.name" :title="option.meta.title">{{
              option.meta.title
            }}</span>
          </MenuItem>
        </Submenu>
      </template>
    </Menu>
    <div v-if="!extend" class="shrink">
      <div
        v-for="menu in menuList"
        :key="menu.path"
        :class="current == menu.name ? 'act' : ''"
        class="shrink-list"
      >
        <div v-if="menu.children.length > 0">
          <Icon v-if="menu.icon" :type="menu.icon"></Icon>
          <span v-if="!menu.icon">{{ menu.meta.title.slice(0, 1) }}</span>
          <ul class="shrink-child">
            <li
              v-for="option in menu.children"
              @click="changePath(option.name, option.blank)"
              :class="current == option.name ? 'act' : ''"
            >
              {{ option.meta.title }}
            </li>
          </ul>
        </div>
        <div v-else @click="changePath(menu.name, menu.blank)">
          <Icon v-if="menu.icon" :type="menu.icon"></Icon>
          <span v-if="!menu.icon">{{ menu.meta.title.slice(0, 1) }}</span>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import { getMenuByRouter, getAccess } from "@/libs/util";
export default {
  name: "side-menu",
  props: {
    extend: {
      type: Boolean,
      default: true,
    },
    menuList: {
      type: Array,
      default: function () {
        return [];
      },
    },
  },
  data() {
    return {};
  },
  watch: {
    menuList: function (val) {
      // this.$router.push({
      //   name: val[0].name,
      // });
    },
    $route: function () {},
  },
  computed: {
    current2() {
      // return this.$route.name;
      return this.$route.matched[0].name;
    },
    current() {
      // return this.$route.name;
      return this.$route.matched[1].name;
    },
    theme() {
      return this.$store.state.app.theme;
    },
    currentOpen() {
      return this.$route.matched[0].name;
    },
  },
  created() {
    // this.menuList = getMenuByRouter(getAccess());
    // window.addEventListener("setItem", () => {
    //   this.menuList = getMenuByRouter(getAccess());
    // });
  },
  methods: {
    changePath(name, blank = false) {
      if (blank) {
        let routeData = this.$router.resolve({
          name: name,
        });
        window.open(routeData.href);
      } else {
        this.$router.push({
          name,
        });
      }
    },
  },
};
</script>

<style lang="less">
.side-menu {
  height: 100%;
  padding: 10px;
  background: #ffffff;
  &::-webkit-scrollbar {
    width: 0;
  }
  &.extend {
    overflow-y: auto;
    overflow-x: hidden;
  }
  .shrink {
    width: 100%;
    .shrink-list {
      text-align: center;
      cursor: pointer;
      position: relative;
      font-size: 28px;
      .shrink-child {
        display: none;
        position: absolute;
        top: 0;
        width: 130px;
        right: -130px;
        background: #fff;
        z-index: 999;
        box-shadow: 2px 0px 10px 0px #ccc;
        li {
          list-style: none;
          font-size: 14px;
          padding: 5px 0;
          margin: 10px;
          &:hover,
          &.act {
            color: #53af3b;
            background: #f2f2f2;
          }
        }
      }
      &.act {
        i,
        span {
          color: #53af3b;
        }
      }
      &:hover {
        i,
        span {
          color: #53af3b;
        }
        .shrink-child {
          display: block;
        }
      }
    }
  }
}
</style>
