/*
 * @Author: winbin
 * @Date: 2022-09-26 09:42:28
 * @LastEditors: winbin
 * @LastEditTime: 2023-02-24 15:37:04
 * @FilePath: \jx2qi\src\libs\api\modules\alarm.js
 * @Description: Desc
 */
import request from '../request'

export default {
    // 登录日志接口相关 - 列表查询
    monitorRuleInfo: (id) => {
        return request({
            url: `system/monitorRule/info/${id}`,
            method: 'post'
        })
    },
    monitorConfigByStationId: (stationId) => {
        return request({
            url: `system/monitorConfig/queryByStationId/${stationId}`,
            method: 'post'
        })
    },
    monitorConfigUpdate: (id, data) => {
        return request({
            url: `system/monitorConfig/update/${id}`,
            data,
            method: 'post'
        })
    },
    monitorRuleQueryConfigId: (id, type) => {
        return request({
            url: `system/monitorRule/queryConfigId/${id}/${type}`,
            method: 'post'
        })
    },
    monitorRuleAdd: (data) => {
        return request({
            url: `system/monitorRule/add`,
            data,
            method: 'post'
        })
    },
    monitorRuleUpdate: (id, data) => {
        return request({
            url: `system/monitorRule/update/${id}`,
            data,
            method: 'post'
        })
    },
    monitorRuleDel: (id) => {
        return request({
            url: `system/monitorRule/delete/${id}`,
            method: 'post'
        })
    },

    factorQueryByStationId: (stationId) => {
        return request({
            url: `/system/factor/queryByStationId/${stationId}`,
            method: 'post'
        })
    },

    alarmList: (data) => {
        return request({
            url: `/system/alarm/list`,
            method: 'post',
            data
        })
    },
    alarmExport: (data) => {
        return request({
            url: `/system/alarm/export`,
            method: 'post',
            data,
            responseType: 'blob'
        })
    },
    alarmInfo: (id) => {
        return request({
            url: `/system/alarm/info/${id}`,
            method: 'post',
        })
    },
    alarmDailyList: (data) => {
        return request({
            url: `/system/alarmDaily/queryList`,
            data,
            method: 'post',
        })
    },
    alarmDailySummaryStatistics: (data) => {
        return request({
            url: `/system/alarmDaily/summaryStatistics`,
            data,
            method: 'post',
        })
    },
    alarmDailyExport: (data) => {
        return request({
            url: `/system/alarmDaily/exportList`,
            data,
            method: 'post',
            responseType: 'blob'
        })
    },
    alarmDailyExportSummaryStatistics: (data) => {
        return request({
            url: `/system/alarmDaily/exportSummaryStatistics`,
            data,
            method: 'post',
            responseType: 'blob'
        })
    },
    alarmDailyCode: () => {
        return request({
            url: `/system/alarmDaily/queryCode`,
            method: 'post',
        })
    },
}