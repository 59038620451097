import request from '../request'

export default {
    getAutoMationFactor: (data) => {
        return request({
            url: `/repo-factor/factor/${id}`,
            method: 'get',
        })
    },
    findIns: () => {
        return request({
            url: ``,
            method: "post"
        })
    },
    saveRule: () => {
        return request({
            url: ``,
            method: "post"
        })
    },
    findRule: () => {
        return request({
            url: ``,
            method: "post"
        })
    },
    getRuleList: () => {
        return request({
            url: ``,
            method: "post"
        })
    },
    getMonitorConfig: () => {
        return request({
            url: ``,
            method: "post"
        })
    },
    updataMonitorConfig: () => {
        return request({
            url: ``,
            method: "post"
        })
    },
    deleteRule: () => {
        return request({
            url: ``,
            method: "post"
        })
    },
    getTenatTree: () => {
        return request({
            url: ``,
            method: "post"
        })
    },
    handleCom: () => {
        return request({
            url: ``,
            method: "post"
        })
    }

}