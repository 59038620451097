import {
  getMenuByRouter,
  setToken,
  getToken,
  setAccess,
  getAccess,
  cleanAll,
  setUserRoutes,
  aes,
  routeDuplicate,
  adminMenu
} from '@/libs/util'
// import {
//   login,
//   getUserInfo,
//   getTenatId
// } from '@/libs/api'

import api from "@/libs/api"


import {
  appRouter,
  appWxRouter
} from '@/libs/routeList'
import Vue from 'vue'
import Vuex from 'vuex'
import 'jsencrypt'
import pako from 'pako'

const encrypt = new JSEncrypt()
encrypt.setPublicKey(process.env.VUE_APP_RSA_PUBLIC_KEY)
encrypt.setPrivateKey(process.env.VUE_APP_RSA_PRIVATE_KEY)
Object.assign(encrypt, aes)

Vue.use(Vuex)

const reset = {
  userName: '',
  userId: '',
  api: '',
  token: getToken(),
  access: getAccess(),
  areaName: '',
  areaCode: '',
  theme: 'primary',
  authorization: [],
  baojing: [],
  tabList: [],
  actTab: "",
}

const app = {
  state: JSON.parse(JSON.stringify(reset)),
  getters: {
    menuList: (state, getters, rootState) => getMenuByRouter(appRouter),
    routerList: (state, getters, rootState) => JSON.parse(JSON.stringify(appRouter)),
    routerWxList: (state, getters, rootState) => JSON.parse(JSON.stringify(appWxRouter)),
    tabList: (state, getters, rootState) => {
      let newArr = [];
      state.tabList.map(val => {
        newArr.push(val)
      })
      return newArr
    }
  },
  mutations: {
    upState(state, params) {
      params.map(val => {
        state[val.key] = val.val
      })
    },
    setAccess(state, access) {
      state.access = access
      setAccess(access)
    },
    setToken(state, token) {
      state.token = token
      setToken(token)
    }
  },
  actions: {
    // 登录
    handleLogin({
      commit
    }, params) {
      cleanAll()
      return new Promise((resolve, reject) => {
        api.login(params).then(res => {
          let userRole = adminMenu(JSON.parse(JSON.stringify(appRouter)))
          if (res) commit('setAccess', setUserRoutes(routeDuplicate(userRole)))
          resolve(res)
        }).catch(err => {
          reject(err)
        })

      })
    },
    // 退出登录
    handleLogOut({
      state,
      commit
    }) {
      return new Promise((resolve, reject) => {
        api.logout().then(res => {
          commit('setToken', '')
          commit('setAccess', [])
          commit('upState', [{
            key: "tabList",
            val: []
          }])
          cleanAll()
          resolve(true)
        })

      })
    },
    // 获取用户相关信息
    getUserInfo({
      state,
      commit
    }) {
      return new Promise((resolve, reject) => {

      })
    },
    getUserAccess({
      state,
      commit
    }) {
      return new Promise((resolve, reject) => {
        resolve(state.access)
      })
    }
  }
}

export default new Vuex.Store({
  modules: {
    app
  },
  strict: true
})
