import request from '../request'

export default {
    // 登录日志接口相关 - 列表查询
    loginLog: (data) => {
        return request({
            url: `/auth/LoginLog/list`,
            method: 'post',
            data
        })
    },
    // 登录认证接口相关 - 获取公钥
    getPuk: () => {
        return request({
            url: `/auth/auth/getPuk`,
            method: 'post'
        })
    },
    // 登录认证接口相关 - 用户登录
    login: (data) => {
        return request({
            url: `/auth/auth/login`,
            data,
            method: 'post'
        })
    },
    // 登录认证接口相关 - 用户登录+校验验证码
    loginByCaptcha: () => {
        return request({
            url: `/auth/auth/loginByCaptcha`,
            method: 'post'
        })
    },
    // 登录认证接口相关 - 用户退出登录
    logout: () => {
        return request({
            url: `/auth/auth/logout`,
            method: 'post'
        })
    },
    // 登录认证接口相关 - 重置密码
    resetPassword: (userId) => {
        return request({
            url: `/auth/auth/resetPassword/${userId}`,
            method: 'post'
        })
    },
    // 验证码接口相关 - 校验滑动验证码
    checkCaptcha: (params,data) => {
        return request({
            url: `/auth/captcha/check`,
            method: 'post',
            params,
            data
        })
    },
    // 验证码接口相关 - 获取验证码图片信息
    genCaptcha: (type) => {
        return request({
            url: `/auth/captcha/gen/${type}`,
            method: 'post'
        })
    }
}









