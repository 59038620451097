import request from '../request'

export default {
  //运维大数据 - 报警统计及列表
  alarmCountList: (data) => {
    return request({
      url: `/system/maintenance/bigdata/alarmcountlist`,
      method: 'post',
      data
    })
  },
  //运维大数据 - 运维统计
  operationCount: (data) => {
    return request({
      url: `/system/maintenance/bigdata/operationcount`,
      method: 'post',
      data
    })
  },
  //运维大数据 - 现场督查统计
  opSiteCount: (data) => {
    return request({
      url: `/system/maintenance/bigdata/opsitesupervisioncount`,
      method: 'post',
      data
    })
  },
  //运维大数据 - 运维站，设备统计
  opStationCount: (data) => {
    return request({
      url: `/system/maintenance/bigdata/stationcount`,
      method: 'post',
      data
    })
  },
  //运维大数据 - 运维站地图列表
  opStationList: (data) => {
    return request({
      url: `/system/maintenance/bigdata/stationlist`,
      method: 'post',
      data
    })
  },
  // 企业与运维厂
  enterpriseRef: () => {
    return request({
      url: `/system/enterprise/queryTree`,
      method: 'post',
    })
  },
  // 根据站点ID查询在线监测数据
  opOnlineData: (data) => {
    return request({
      url: `/system/onlineData/queryOnlineData`,
      method: 'post',
      data
    })
  },
  // 运维任务统计-平均自评分
  opAvgScore: (data) => {
    return request({
      url: `/system/maintenance/bigdata/analysis/operationcount/avgscore`,
      method: 'post',
      data
    })
  },
  // 远程督查统计-远程督查平均分
  opSuperAvgScore: (data) => {
    return request({
      url: `/system/maintenance/bigdata/analysis/operationsupervision/avgscore`,
      method: 'post',
      data
    })
  },
  // 运维任务统计-平均自评分趋势
  opScoreTrend: (data) => {
    return request({
      url: `/system/maintenance/bigdata/analysis/operationcount/avgs`,
      method: 'post',
      data
    })
  },
  // 远程督查统计-远程督查平均分趋势
  opSuperScore: (data) => {
    return request({
      url: `/system/maintenance/bigdata/analysis/operationsupervision/avgs`,
      method: 'post',
      data
    })
  },
  // 站点统计分析-在离线统计
  staCount: (data) => {
    return request({
      url: `/system/station/analysis/count`,
      method: 'post',
      data
    })
  },
  // 站点统计分析-饼状图
  staStatisPie: (data) => {
    return request({
      url: `/system/station/analysis/count/piechart`,
      method: 'post',
      data
    })
  },
  // 站点状态统计-饼状图
  staStatusPie: (data) => {
    return request({
      url: `/system/station/analysis/stationstatus/piechart`,
      method: 'post',
      data
    })
  },
  // 设备状态统计分析-设备类型下拉
  equipType: () => {
    return request({
      url: `/system/equipment/analysis/types`,
      method: 'post',
    })
  },
  // 设备统计分析
  equipConut: (data) => {
    return request({
      url: `/system/equipment/analysis/count`,
      method: 'post',
      data
    })
  },
  // 设备状态统计分析
  equipStatusConut: (data) => {
    return request({
      url: `/system/equipment/analysis/status/count`,
      method: 'post',
      data
    })
  },
  // 设备品牌统计
  equipBrandConut: (data) => {
    return request({
      url: `/system/equipment/analysis/type/count`,
      method: 'post',
      data
    })
  },
  // 数据总览-企业，运维厂统计
  enterConut: (data) => {
    return request({
      url: `/system/enterprise/analysis/count`,
      method: 'post',
      data
    })
  },
  // 数据总览-企业，运维新增趋势
  enterTrend: (data) => {
    return request({
      url: `/system/enterprise/analysis/newtrend`,
      method: 'post',
      data
    })
  },
  // 数据总览-站点统计
  stationConut: (data) => {
    return request({
      url: `/system/station/analysis/station/count`,
      method: 'post',
      data
    })
  },
  // 数据总览-站点新增趋势
  siteTrend: (data) => {
    return request({
      url: `/system/station/analysis/station/newtrend`,
      method: 'post',
      data
    })
  },
  // 数据总览-设备统计
  equipCount: (data) => {
    return request({
      url: `/system/equipment/analysis/num/count`,
      method: 'post',
      data
    })
  },
  // 数据总览-设备新增趋势
  equipTrend: (data) => {
    return request({
      url: `/system/equipment/analysis/num/newtrend`,
      method: 'post',
      data
    })
  },
  // 单站点分析-运维统计
  singleOp: (data) => {
    return request({
      url: `/system/maintenance/bigdata/station/operation/count`,
      method: 'post',
      data
    })
  },
  // 单站点分析-试剂统计
  singleReag: (data) => {
    return request({
      url: `/system/maintenance/bigdata/station/reagent/count`,
      method: 'post',
      data
    })
  },
  // 单站点分析-试剂使用趋势
  singleReagTrend: (data) => {
    return request({
      url: `/system/maintenance/bigdata/station/reagent/useRate`,
      method: 'post',
      data
    })
  },
  // 单站点分析-现场督查统计
  singleScene: (data) => {
    return request({
      url: `/system/maintenance/bigdata/station/supervision/count`,
      method: 'post',
      data
    })
  },
  // 单站点分析-试剂超期更换
  singleReagChange: (data) => {
    return request({
      url: `/system/maintenance/bigdata/station/reagent/replace`,
      method: 'post',
      data
    })
  },
  // 单站点分析-故障统计-数据异常现象占比
  singleEquipRate: (data) => {
    return request({
      url: `/system/maintenance/bigdata/station/equipment/dataexception`,
      method: 'post',
      data
    })
  },
  // 单站点分析-故障统计-采样及前处理单元
  singleEquipUnit: (data) => {
    return request({
      url: `/system/maintenance/bigdata/station/equipment/dataexception/detail`,
      method: 'post',
      data
    })
  },
  // 单站点分析-运维工程师工时消耗
  singleWork: (data) => {
    return request({
      url: `/system/maintenance/bigdata/station/workinghours/count`,
      method: 'post',
      data
    })
  },
  // 单站点分析-消耗工时趋势
  singleWorkTrend: (data) => {
    return request({
      url: `/system/maintenance/bigdata/station/workinghours/trend`,
      method: 'post',
      data
    })
  },
  // 系统性水环境-站点效能排名Top10
  waterStaTop: (data) => {
    return request({
      url: `/system/maintenance/bigdata/station/efficiency/station10`,
      method: 'post',
      data
    })
  },
  // 系统性水环境-站点排放波动性排名Top10
  waterWaveTop: (data) => {
    return request({
      url: `/system/maintenance/bigdata/station/wave/station10`,
      method: 'post',
      data
    })
  },
  // 系统性水环境-xxx站点跃迁趋势
  waveTrend: (data) => {
    return request({
      url: `/system/maintenance/bigdata/station/wavetrend`,
      method: 'post',
      data
    })
  },
  alarmCount: (data) => {
    return request({
      url: `/system/maintenance/bigdata/alarmCount`,
      method: 'post',
      data
    })
  },
  // 排放标准下拉
  emissionRef: () => {
    return request({
      url: `/system/emissionStandard/select`,
      method: 'post',
    })
  },
}